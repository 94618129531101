import React from "react";

function Icon({ width, height, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 1600 1600"
    >
      <circle
        fill="currentColor"
        cx="106.667"
        cy="106.667"
        r="106.667"
        transform="translate(1037.479 598.333)"
      />
      <circle
        fill="currentColor"
        cx="106.667"
        cy="106.667"
        r="106.667"
        transform="translate(589.185 598.333)"
      />
      <path
        fill="currentColor"
        d="M-4360-550c-442.666,0-800,357.335-800,800s357.334,800,800,800,800-357.335,800-800S-3917.334-550-4360-550Zm0,1493.335c-384,0-693.334-309.335-693.334-693.335S-4744-443.335-4360-443.335-3666.666-134-3666.666,250-3976,943.335-4360,943.335Z"
        transform="translate(5160 550)"
      />
      <rect
        fill="currentColor"
        width="94.999"
        height="214.49"
        transform="matrix(0.643, -0.766, 0.766, 0.643, 1025.11, 385.692)"
      />
      <rect
        fill="currentColor"
        width="214.49"
        height="94.999"
        transform="translate(499.517 450.791) rotate(-40)"
      />
      <path
        fill="currentColor"
        d="M-4660.344-305.664c-137.93-93.155-303.45-32.6-389.656,130.41l65,48.47s56.064-71.955,137.2-55.645c77.8,15.645,93,190.155,249.527,53.755L-4550-179.909C-4577.586-231.144-4615.521-277.719-4660.344-305.664Z"
        transform="translate(5600 1351.784)"
      />
    </svg>
  );
}

export default Icon;
