import React, { Component } from "react";
import Dialogue from "./dialogue/dialogue";
import "../../styles/slide11.scss";
import Container from "react-bootstrap/Container";
import classNames from "classnames";
import Link from "@material-ui/core/Link";
import ConfirmLeaveChatModal from "../modals/ConfirmLeaveChatModal";

const DIALOGUE_SPECIAL_ACTIONS = { EXIT: "Exit", IMAGE: "Image" };

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      background: null,
      nextId: 0,
      msgArray: [],
      responseMessages: [],
      chat: {},
      chats: [],
      isBotTyping: false,
      isTheEnd: false,
      isModalShow: false,
    };
    this.minimumMessageDelay = 0;
    this.interval = null;
  }

  async componentWillMount() {
    const { chatID } = this.props;
    const chat = require(`./resources/${chatID}/chat`).chat;
    const chats = this.getChats(chat);
    console.log("chats", chats);
    this.setState({
      chat: chats,
      background: require(`./resources/${chatID}/background_${chatID}.png`),
    });
    this.nextMessage();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  componentWillUnmount() {
    const { characteristics } = this.props;
    localStorage.setItem("characteristics", JSON.stringify(characteristics));
  }

  changeWindowState = () =>
    this.setState({ isModalShow: !this.state.isModalShow });

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  };

  getChats = (chatID) => {
    Dialogue.parse("Chats", chatID);
    const chat = Dialogue.dialogues;
    return chat;
  };

  setDelayForEachMessage = () => {
    const currentMessage = Dialogue.__getDialogue("Chats", this.state.nextId);
    this.minimumMessageDelay = Math.floor(
      (currentMessage.text.length * 100) / 1.75
    );
    this.minimumMessageDelay =
      this.minimumMessageDelay > 1000 ? this.minimumMessageDelay : 1000;
  };

  nextMessage = () => {
    let currentMessage = Dialogue.__getDialogue("Chats", this.state.nextId);
    if (this.handleSpecialAction(currentMessage)) {
      this.setState({ isBotTyping: false, isTheEnd: true });
      return;
    }
    this.setState({ isBotTyping: true });
    this.setDelayForEachMessage();
    this.interval = setInterval(() => {
      if (currentMessage && currentMessage.next) {
        this.handleSpecialAction(currentMessage);
        this.setState({
          nextId: currentMessage.next,
          msgArray: [...this.state.msgArray, currentMessage],
        });
        clearInterval(this.interval);
        this.nextMessage();
      } else {
        this.setState({
          msgArray: [...this.state.msgArray, currentMessage],
          responseMessages: currentMessage.responses,
        });
        this.setState({ isBotTyping: false });
        clearInterval(this.interval);
        return;
      }
    }, this.minimumMessageDelay);
  };

  handleSpecialAction(currentMessage) {
    if (currentMessage.text.indexOf("||") === 0) {
      const string = currentMessage.text.substr(2);
      const imgString = string.split("Image");
      let number = "0";
      if (imgString[1]) number = imgString[1];
      const image = require(`./resources/${this.props.chatID}/img_${this.props.chatID}_${number}.png`);

      switch (string) {
        case DIALOGUE_SPECIAL_ACTIONS.EXIT:
          return true;
        case DIALOGUE_SPECIAL_ACTIONS.IMAGE + number:
          currentMessage.image = image;
          return false;
        default:
          return false;
      }
    }
    return false;
  }

  sendMessage = (item) => {
    const { changeCharacteristics } = this.props;
    let currentMessage = Dialogue.__getDialogue("Chats", item);
    if (currentMessage.action) {
      changeCharacteristics(currentMessage.action);
    }
    currentMessage.user = true;
    this.setState(
      {
        responseMessages: [],
        msgArray: [...this.state.msgArray, currentMessage],
        nextId: currentMessage.next,
      },
      () => this.nextMessage()
    );
  };

  render() {
    const { responseMessages, background, isTheEnd, isModalShow } = this.state;
    const { chatID, setSlide } = this.props;
    const imageBlockClass = classNames({
      image: chatID === 1,
      fullImage: chatID !== 1,
    });
    const dotsClass = classNames({
      loader: true,
      black: chatID === 3 || chatID === 5 || chatID === 10,
    });

    return (
      <Container>
        <div className="slide-11 chatsWrapper">
          <div
            className="messagesWrapper"
            style={{ backgroundImage: `url(${background})` }}
          >
            {this.state.msgArray.map((item, index) => (
              <div
                key={index}
                className={classNames({
                  userMessageBlocks: item.user,
                  messageBlocks: !item.user,
                })}
              >
                {item.image ? (
                  <p>
                    <img
                      src={item.image}
                      alt="img"
                      className={imageBlockClass}
                    />
                  </p>
                ) : (
                  <p>{item.text}</p>
                )}
              </div>
            ))}
            {this.state.isBotTyping && (
              <div className="loader-wrapper">
                <div className={dotsClass}>Loading...</div>
              </div>
            )}
            <div
              style={{ float: "left", clear: "both", paddingBottom: "20px" }}
              ref={(el) => {
                this.messagesEnd = el;
              }}
            ></div>
          </div>
          <div className="inputWrapper">
            {!isTheEnd &&
              responseMessages.map((item, index) => (
                <button
                  key={index}
                  className="button"
                  onClick={() => this.sendMessage(item)}
                >
                  {Dialogue.__getDialogue("Chats", item).text}
                </button>
              ))}
            {isTheEnd && (
              <button className="button" onClick={() => setSlide(0)}>
                Beende den Chat und zurück zur Auswahl
              </button>
            )}
          </div>
          <div className="slide-link">
            <Link
              component="button"
              variant="body1"
              underline="always"
              onClick={this.changeWindowState}
            >
              Chat abbrechen
            </Link>
          </div>
        </div>
        <ConfirmLeaveChatModal
          show={isModalShow}
          hide={this.changeWindowState}
          cancelChat={() => setSlide(0)}
        />
      </Container>
    );
  }
}

Chat.defaultProps = {
  chatID: 4,
};

export default Chat;
