export const chat = `0 Hallo, schön dass du mich kennenlernen willst. -> 1
1 Ich hoffe, sie haben nicht schon wieder meinen Preis erhöht. [2,3,4]
2 Ich finde den Preis angemessen. -> 5
3 Ja, du warst schon ganz schön teuer. Aber du hast ja auch einen tollen Bart. -> 5
4 Dass mit dir Kapital gemacht wird, ist schon eine Ironie der Geschichte. -> 5
5 Ich habe den Macher*innen des Games tausendmal gesagt, dass ich das auch umsonst machen würde. -> 6
6 Aber nein - jetzt müsst ihr nicht nur eure Arbeitskraft, sondern auch noch eure Emotionen verkaufen. -> 7
7 Tut mir echt leid. [8, 9, 10]
8 Aber Gutes hat immer seinen Preis. -> 11
9 Aber es macht richtig Spaß. -> 11
10 Schon ok - chill mal. -> 11
11 Ja, ok. Verstehe. -> 12
12 Ich vergesse immer, dass es euch schwerer fällt als jeder anderen Generation zuvor, die wahren Verhältnisse zu erkennen. [13, 14]
13 Was sind denn die “wahren” Verhältnisse? -> 16
14 Elvis ist gar nicht tot? -> 15
15  Haha, mittelmäßig lustig. -> 16
16 Die wahren Verhältnisse: Ihr lebt in einem Klassenkampf und wisst es nicht. -> 17
17 Ihr seid Lohnsklaven einer globalen Burgeoisie. -> 18
18 Ihr werdet unterdrückt und lenkt euch in buten Konsumwelten ab. [19, 20, 21]
19 Hmm. Ich finde mein Leben eigentlich ok. -> 23
20 Was heißt Burgeoisie? -> 37
21 Was heißt Lohnsklave? -> 44
22 Ja, weil du nicht über die wahren Verhältnisse aufgeklärt bist. -> 23
23 Vielleicht hast du deinen kleinen privaten Frieden gefunden. -> 24
24 Aber ein goldener Käfig ist immer noch ein Käfig. [25, 26]
25 Das klingt übel. Was kann ich denn dagegen tun? -> 27
26 Zeig mir den Weg in die Freiheit. -> 27
27 Wie wäre es mit einer kleinen Bettlektüre? -> 102
28 Wow, was ist das? -> 31
29 Sind das Bücher? -> 31
30 Ich befürchte mein Bett ist zu klein… -> 31
31 Das ist die MEGA -> 32
32 Die Marx-Engels-Gesamtausgabe -> 33
33 Sie wird 114 Bände umfassen, wenn sie jemals fertig wird. -> 34
34 Das habe ich alles mit meinem Kumpel Friedrich Engels runtergerattert. [35, 36]
35 Warum habt ihr so viel geschrieben? -> 53
36 Wie soll mich dieser Bücherhaufen befreien? -> 53
37 Burgeosie ist die herrschende soziale Klasse. -> 38
38 Als Arbeiter*in oder Angestellte*r  sind das die, die jeden Tag deine Zeit und Arbeitskraft kaufen -> 39
39 Damit du dich für sie jeden Tag langmachst -> 40
40 Und am Ende gerade so die Miete  zahlen kannst. [41, 42, 43]
41 Ich verstehe es noch nicht so ganz. -> 27
42 Und was kann man dagegen machen? -> 27
43 Ich sehe das anders. -> 27
44 Lohnsklave ist, wer über keine eigenen Produktionsmittel verfügt. -> 45
45 Also zB. keine Fabrik oder ein Unternehmen besitzt. -> 46
46 Als Lohnsklave verkaufst du jeden Tag wieder neu deine Arbeitskraft. -> 47
47 Der Unterschied zum normalen Sklaventum besteht darin, -> 48
48 dass du dir deinen Herren oder deine Herrin aussuchen kannst (zumindest manchmal) -> 49
49 Yippie, yeah, yeah, Yippieh yeah - das ist die große Errungenschaft unserer Zeit! [50, 51, 52]
50 Ich weiß nicht, ob ich es schon verstanden habe. -> 22
51 Das klingt mir zu negativ. -> 22
52 Mir geht´s aber doch gut. -> 22
53 Wir haben die gesellschaftlichen Verhältnisse analysiert! -> 54
54 Und herausgefunden: -> 55
55 Das Sein bestimmt das Bewusstsein!! [56, 57, 58]
56 Ich glaube mein Bewusstsein schwindet langsam dahin. -> 59
57 Mein Sein braucht bald einen Kaffee. -> 59
58 Ok, aber was soll das heißen? -> 59
59 In aller Kürze heißt es: -> 60
60 Wenn wir frei sein wollen, dann müssen wir unsere Art zu leben ändern. -> 61
61 Und das Tolle ist: Das kann man machen. [62, 63]
62 Ich bin jetzt schon so kaputt vom Alltag. Das nun auch noch. -> 64
63 Das klingt aufregend. Wie fange ich damit an? -> 64
64 Sich politisch zu bilden und kritisch die Verhältnisse zu hinterfragen ist ein Anfang. -> 65
65 Und es gibt mehr Energie als es nimmt. -> 66
66 Zum Schluss noch eine Zahl über den Zustand der Gesellschaft global -> 67
67 Das reichste Prozent der Menschen besitzt so viel, wie 87 Prozent aller. -> 68
68 Findest Du das fair? [69, 70, 71]
69 Nein, natürlich nicht. -> 72
70 Hmm, ich arbeite auch hart für mein Geld. -> 72
71 Was interessieren mich die anderen? -> 72
72 Lies mal das “Kommunistische Manifest” -> 73
73 Da findest du Inspiration. -> 74
74 Auf dass deine freie Entwicklung die Bedingung für die freie Entwicklung aller anderen werden möge. [75, 76, 77]
75 Karl, du bist echt ein krasser Freak! -> 101
76 Ich sag mal: Man marx oder man marx nicht.. -> 101
77 Wir sehen uns bei der Revolution! -> 101

101 ||Exit
102 ||Image0 [28, 29, 30]
`
