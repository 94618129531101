export const chat = `0 Wow, das ist unfassbar!!! -> 1
1 Bin hier gerade bei der Eröffnung des neuen Media Markts!!! -> 2
2 Die Leute rennen die Bude ein XD - der totale Kaufrausch!!! -> 3
3 Gibt ein paar richtig geile Schnäppchen! [4,5,6]
4 Cool, ich wäre gern mitgekommen. -> 7 {a1-3}
5 Für mich wäre das nichts, ich shoppe lieber online und in Ruhe... -> 8 {a1-2}
6 Ich shoppe ja nicht soooo gern :D -> 8 {a1+1}
7 Hahaha, ok, next time! -> 9
8 Hahaha, ok :-) -> 9
9 ... aber eigentlich nicht zum Lachen hier, macht irgendwie auch Angst, bin mittendrin, was für Massen... -> 10
10 Egal, Augen zu und durch. Soll ich dir etwas mitbringen? Alles super günstig!!! [11,12,13]
11 Ja, voll gerne, Hauptsache ein Schnäppchen. -> 14 {a1-3}
12 Könnte einen neuen Flachbildschirm gebrauchen ;-)  -> 14 {a1-3}
13 Ne Danke... brauche eigentlich nichts... -> 14 {a1+1}
14 Hahah, ich glaube ich kaufe für alle, die ich lieb habe, erst einmal diese günstigen USB Sticks hier ... weniger als 1 Euro alles. Unfassbar. Für dich auch? [15,16,17]
15 Ja, mega gern! -> 18 {a1-1}
16 Wenn es fast nichts kostet! -> 18 {a1-1}
17 Brauche ich alles eigentlich nicht. -> 18 {a1+1}
18 Ok, hab jetzt mal so viele gegriffen, wie ich konnte. -> 19
19 Ich kämpfe mich mal weiter durch die Massen hier. -> 20
20 Boah, das musst du gesehen haben. Fast wie die Tiere, die Leute XD -> 21
21 Morgen ist hier auch noch alles runtergesetzt... [22,23,24]
22 Super, echt gut zu wissen... :-D -> 25 {a1-2}
23 Ok, online ist trotzdem bestimmt noch billger :-D -> 25 {a1-3}
24 Gut, ich kaufe lieber gebraucht. -> 25 {a1-0}
25 Auf jeden Fall ein Erlebnis hier! Machs gut! -> 101

101 ||Exit`