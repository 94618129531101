import React from "react";

function Icon({ width, height, className }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 1000 1000"
      width={width}
      height={height}
    >
      <g id="Gruppe_90">
        <path
          id="Pfad_71"
          fill="currentColor"
          d="M417.7,440.6c0-0.7-0.1-1.4-0.1-2.1l30.1-35.9L310.4,287.3l-38.2,45.5l65.1,54.6
		c-21.4,9.1-35.2,30-35.2,53.2c0,31.9,25.9,57.8,57.8,57.8c0,0,0,0,0,0C391.8,498.4,417.7,472.6,417.7,440.6z"
        />
        <path
          id="Pfad_72"
          fill="currentColor"
          d="M500,0C223.3,0,0,223.3,0,500s223.3,500,500,500s500-223.3,500-500S776.7,0,500,0z M500,933.3
		C261.2,933.8,67.1,740.6,66.7,501.7c0-0.6,0-1.2,0-1.7C66.2,261.2,259.4,67.1,498.3,66.7c0.6,0,1.2,0,1.7,0
		c238.8-0.5,432.9,192.8,433.3,431.6c0,0.6,0,1.2,0,1.7c0.5,238.8-192.8,432.9-431.6,433.3C501.2,933.3,500.6,933.3,500,933.3
		L500,933.3z"
        />
        <path
          id="Pfad_73"
          fill="currentColor"
          d="M689.6,287.3L552.2,402.5l30.1,35.9c0,0.7-0.1,1.4-0.1,2.1c0,31.9,25.9,57.8,57.8,57.8
		c31.9,0,57.8-25.9,57.8-57.8c0,0,0,0,0,0c0-23.2-13.9-44.2-35.2-53.2l65.1-54.6L689.6,287.3z"
        />

        <rect
          id="Rechteck_30"
          x="469.4"
          y="600.9"
          transform="matrix(0.1736 -0.9848 0.9848 0.1736 -286.2958 1079.3302)"
          fill="currentColor"
          width="61.1"
          height="218.8"
        />
      </g>
    </svg>
  );
}

export default Icon;
