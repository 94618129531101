import React from "react";

function Icon({ width, height, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 1600 1600"
    >
      <circle
        fill="currentColor"
        cx="125"
        cy="125"
        r="125"
        transform="translate(450.853 434.134)"
      />
      <circle
        fill="currentColor"
        cx="125"
        cy="125"
        r="125"
        transform="translate(899.146 434.134)"
      />
      <path
        fill="currentColor"
        d="M-3860-550c-442.666,0-800,357.335-800,800s357.334,800,800,800,800-357.335,800-800S-3417.334-550-3860-550Zm0,1493.335c-384,0-693.334-309.335-693.334-693.335S-4244-443.335-3860-443.335-3166.666-134-3166.666,250-3476,943.335-3860,943.335Z"
        transform="translate(4660 550)"
      />
      <path
        fill="currentColor"
        d="M-4221.486-317.432C-4334.584-424.1-4470.31-354.762-4541-168.1c70.69,186.665,206.416,256,319.514,149.335,36.761-32,67.866-85.335,90.486-144l-3.6-5.335,3.6-5.335C-4153.62-232.1-4184.725-285.432-4221.486-317.432Z"
        transform="translate(5136 1279.257)"
      />
    </svg>
  );
}

export default Icon;
