import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import Zoom from "@material-ui/core/Zoom";
import Slider from "react-slick";
import DatenschutzDetailsModal from "./modals/DatenschutzDetailsModal";
import ImpressumDetailsModal from "./modals/ImpressumDetailsModal";
import EgosBarDetailModal from "./modals/EgosBarDetailModal";
import Link from "@material-ui/core/Link";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/slide1.scss";

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "red",
    color: "white",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(14),
  },
}))(Tooltip);

const SLIDER_SETTINGS = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 1215,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 500,
      settings: {
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const SLIDER_CONTENT = [
  {
    id: 1,
    name: "Kaufmannsfrau",
    image: require("../static/sliders/slide_1.jpg"),
    cost: 2999,
  },
  {
    id: 2,
    name: "Maschinenmensch",
    image: require("../static/sliders/slide_2.jpg"),
    cost: 23000,
  },
  {
    id: 3,
    name: "Zwei Arbeiter",
    image: require("../static/sliders/slide_3.jpg"),
    cost: 4050,
  },
  {
    id: 4,
    name: "Karl Marx",
    image: require("../static/sliders/slide_4.jpg"),
    cost: 1818,
  },
  {
    id: 5,
    name: "Give Us, Dear",
    image: require("../static/sliders/slide_5.jpg"),
    cost: 16000,
  },
  {
    id: 6,
    name: "Steingeld",
    image: require("../static/sliders/slide_6.jpg"),
    cost: 9999,
  },
  {
    id: 7,
    name: "Die Verehrung des Mammon",
    image: require("../static/sliders/slide_7.jpg"),
    cost: 3333,
  },
  {
    id: 8,
    name: "Porträt von Olivia Peyton Murray Cutting",
    image: require("../static/sliders/slide_8.jpg"),
    cost: 8999,
  },
  {
    id: 9,
    name: "Available for Sale",
    image: require("../static/sliders/slide_9.jpg"),
    cost: 99,
  },
  {
    id: 10,
    name: "Angela Merkels Mobiltelefon Siemens S55",
    image: require("../static/sliders/slide_10.jpg"),
    cost: 50000,
  },
  {
    id: 11,
    name: "Der Fall Daidalos und Ikarus (Otto Lilienthal)",
    image: require("../static/sliders/slide_11.jpg"),
    cost: 5600,
  },
];
class Slide1 extends Component {
  state = {
    isImpressumModal: false,
    isEgoBarDetailModal: false,
    isDatenschutzModal: false,
  };

  onSelectedSlider({ id, cost, name }) {
    const chat = { id, cost, name };
    const { egos, onSelectedChat } = this.props;
    if (egos >= chat.cost) {
      onSelectedChat(chat);
    }
  }

  get isValidCharacteristics() {
    const { characteristics } = this.props;
    return Object.values(characteristics).some(
      (characteristics) => characteristics !== null
    );
  }

  changeWindowState = (isModal) =>
    this.setState({ [isModal]: !this.state[isModal] });

  render() {
    const {
      egos,
      setSlide,
      userInfo: { isUserAccept },
    } = this.props;

    return (
      <Container>
        <div className="slide-1">
          <h1 className="slide-title">
            Das kapi <br /> talismus <br /> game
          </h1>
          <div className="egos-details">
            <div className="egos-details__img">
              <StyledTooltip
                TransitionComponent={Zoom}
                title={`So wird gespielt`}
              >
                <img
                  src={require("../static/images/question.png")}
                  alt="Einzelheiten"
                  onClick={() => this.changeWindowState("isEgoBarDetailModal")}
                />
              </StyledTooltip>
            </div>
            <div className="egos-details__text">
              <p>Dein kapital</p>
              <h2>{parseFloat(egos).toLocaleString("de-DE")} Egos</h2>
            </div>
            <div className="egos-details__img">
              <img
                src={require("../static/images/egos_mini.svg")}
                alt="Einzelheiten"
              />
            </div>
          </div>
          <div className="main-details">
            <div className="main-details__text">
              <h2>
                Warenkorb 
              </h2>
              <p>Lade dein Ego-Konto auf, um die Exponate zu shoppen</p>
            </div>
            <div className="main-details__buttons slide-buttons">
              <Button
                variant="contained"
                onClick={() => setSlide(2)}
                disabled={!isUserAccept}
              >
                Egos verdienen
              </Button>
              <Button
                variant="contained"
                onClick={() => setSlide(11)}
                disabled={!this.isValidCharacteristics}
              >
                Quittung erhalten
              </Button>
            </div>
          </div>

          <Slider {...SLIDER_SETTINGS}>
            {SLIDER_CONTENT.map((slide, index) => (
              <div
                className="card-wrapper"
                key={index}
                onClick={() => this.onSelectedSlider(slide)}
              >
                <StyledTooltip
                  disableHoverListener={egos >= slide.cost}
                  TransitionComponent={Zoom}
                  title={`Brauche noch ${parseFloat(
                    slide.cost - egos
                  ).toLocaleString("de-DE")} egos`}
                >
                  <Card
                    style={{
                      width: "238px",
                      background: `url(${slide.image}) no-repeat center center / contain`,
                      cursor: egos >= slide.cost ? "pointer" : "auto",
                    }}
                  ></Card>
                </StyledTooltip>
              </div>
            ))}
          </Slider>
          <div className="slide-link">
            <Link
              component="button"
              variant="body1"
              underline="always"
              onClick={() => this.changeWindowState("isDatenschutzModal")}
            >
              Datenschutz
            </Link>
            {" | "}
            <Link
              component="button"
              variant="body1"
              underline="always"
              onClick={() => this.changeWindowState("isImpressumModal")}
            >
              Impressum
            </Link>
          </div>
        </div>
        <DatenschutzDetailsModal
          show={this.state.isDatenschutzModal}
          onHide={() => this.changeWindowState("isDatenschutzModal")}
        />
        <ImpressumDetailsModal
          show={this.state.isImpressumModal}
          onHide={() => this.changeWindowState("isImpressumModal")}
        />
        <EgosBarDetailModal
          show={this.state.isEgoBarDetailModal}
          onHide={() => this.changeWindowState("isEgoBarDetailModal")}
        />
      </Container>
    );
  }
}

export default Slide1;
