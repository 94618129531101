import React from "react";
import Container from "react-bootstrap/Container";
import Button from "@material-ui/core/Button";
import "../styles/slide13.scss";

function Slide13({ receiptVideoLink, setSlide }) {
  return (
    <Container>
      <div className="slides slide-13">
        <iframe
          className="slide-video"
          src={receiptVideoLink}
          frameBorder="0"
          allowFullScreen
          title="slide-video"
        ></iframe>
        <div className="slide-buttons">
          <Button onClick={() => setSlide(11)}> &#60; zurück </Button>
        </div>
      </div>
    </Container>
  );
}

export default Slide13;
