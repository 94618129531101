import React, { Component } from "react";

import HappyIcon from "./icons/HappyIcon.js";
import SadIcon from "./icons/SadIcon.js";
import NeutralIcon from "./icons/NeutralIcon.js";
import SurprizedIcon from "./icons/SurprizedIcon";
import DisgustedIcon from "./icons/DisgustedIcon";
import ScaredIcon from "./icons/ScaredIcon";
import AngryIcon from "./icons/AngryIcon";

import "../styles/slide7.scss";

class Slide7 extends Component {
  state = {};

  componentDidMount() {
    setTimeout(() => this.props.setSlide(7), 5000);
  }

  get Icon() {
    let Icon = null;

    switch (this.props.emotion) {
      case "happy":
        Icon = HappyIcon;
        break;
      case "sad":
        Icon = SadIcon;
        break;
      case "neutral":
        Icon = NeutralIcon;
        break;
      case "surprised":
        Icon = SurprizedIcon;
        break;
      case "fearful":
        Icon = ScaredIcon;
        break;
      case "disgusted":
        Icon = DisgustedIcon;
        break;
      case "angry":
        Icon = AngryIcon;
        break;
      default:
        Icon = NeutralIcon;
        break;
    }
    return Icon;
  }

  render() {
    const { Icon } = this;

    return (
      <div className="slides slide-7">
        <h2>
          Deine Leistung <br /> wird analysiert …
        </h2>
        <Icon className="happy-icon" width="150" height="150" />
      </div>
    );
  }
}

export default Slide7;
