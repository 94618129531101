import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";

export default function ConfirmLeaveChatModal({ show, hide, cancelChat }) {
  const handleClose = (isLeaveChat) => {
    if (isLeaveChat) {
      cancelChat();
    }
    hide();
  };

  return (
    <div>
      <Dialog
        open={show}
        onClose={hide}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Wirklich abbrechen?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => handleClose(false)} color="primary">
            Nein
          </Button>
          <Button onClick={() => handleClose(true)} color="secondary" autoFocus>
            Ja
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
