import React from "react";

function Icon({ width, height, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 1600 1600"
    >
      <path
        fill="currentColor"
        d="M-6658.667-134c-149.338,0-288-85.335-346.666-224l-80,53.335C-6968.005-70-6680,26-6440-91.335c90.672-48,170.672-122.665,213.335-213.33l-80-53.335C-6370.667-219.335-6509.331-134-6658.667-134Z"
        transform="translate(7458.668 1318)"
      />
      <circle
        fill="currentColor"
        cx="106.667"
        cy="106.667"
        r="106.667"
        transform="translate(426.665 480)"
      />
      <path
        fill="currentColor"
        d="M-6861.335-240.665a106.672,106.672,0,0,0,106.669-106.67A106.668,106.668,0,0,0-6861.335-454,106.663,106.663,0,0,0-6968-347.335,106.667,106.667,0,0,0-6861.335-240.665Z"
        transform="translate(7928 934)"
      />
      <path
        fill="currentColor"
        d="M-6360-550c-442.672,0-800,357.335-800,800s357.328,800,800,800,800-357.335,800-800S-5917.335-550-6360-550Zm0,1493.335c-384,0-693.335-309.335-693.335-693.335S-6744-443.335-6360-443.335-5666.666-134-5666.666,250-5976,943.335-6360,943.335Z"
        transform="translate(7160 550)"
      />
    </svg>
  );
}

export default Icon;
