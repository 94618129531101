import React from "react";
import "../styles/slide2.scss";
import Container from "react-bootstrap/Container";
import Button from "@material-ui/core/Button";

function Slide2(props) {
  const {
    chat: { id, name, cost },
    onConfirmChat,
    setSlide,
  } = props;

  return (
    <Container>
      <div className="slides slide-2">
        <h2>
          Möchtest du für <br /> {parseFloat(cost).toLocaleString("de-DE")} Egos mit <br />
          »{name}« <br /> chatten?
        </h2>
        <div className="slide-img">
          <img
            src={require(`./slide11/resources/${id}/background_${id}.png`)}
            alt="icon2"
          />
        </div>
        <div className="slide-buttons">
          <Button variant="contained" onClick={onConfirmChat}>
            Ja, ich will!
          </Button>
          <Button variant="contained" onClick={() => setSlide(0)}>
            Nein, lieber nicht.
          </Button>
        </div>
      </div>
    </Container>
  );
}

export default Slide2;
