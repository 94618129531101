import React, { Component } from "react";
import "../styles/slide4.scss";
import Container from "react-bootstrap/Container";
import EgosIcon from "./icons/EgosIcon.js";
import Button from "@material-ui/core/Button";
import SadIcon from "./icons/SadIcon.js";

class Slide4 extends Component {
  state = {
    egos: 40000,
  };

  componentWillUnmount() {
    const { takeEgosOnce } = this.props;
    takeEgosOnce(this.state.egos);
  }

  render() {
    const { setSlide } = this.props;
    const { egos } = this.state;
    return (
      <Container>
        <div className="slides slide-4">
          <div>
            <h2>
              Da wir deine Emotionen gerade nicht erkennen können (kein
              Kamera-Zugriff), zeigen wir uns generös. Hier ist ein Gutschein
              über <span>{parseFloat(egos).toLocaleString("de-DE")}</span> Egos.
              Nicht schlecht, oder?
            </h2>
          </div>
          <EgosIcon className="egos-icon" width="150" height="150" />
          <div className="slide-buttons">
            <Button variant="contained" onClick={() => setSlide(0)}>
              Egos nehmen
            </Button>
          </div>
        </div>
      </Container>
    );
  }
}
export default Slide4;
