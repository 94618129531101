import React from "react";

import bouncingArrow from "../static/images/bouncing-arrow.svg";

function BouncingArrow({ text }) {
  return (
    <div className="start-button">
      <div className="start-button__text">{text}</div>
      <img className="start-button__img" src={bouncingArrow} alt="" />
    </div>
  );
}

export default BouncingArrow;
