import React, { Component } from "react";
import "../styles/slide6.scss";
import Container from "react-bootstrap/Container";
import SadIcon from "./icons/SadIcon.js";
import Button from "@material-ui/core/Button";

class Slide6 extends Component {

  render() {
    const { setSlide } = this.props;

    return (
      <Container>
        <div className="slides slide-6">
          <div>
            <h2>
              Gewähren Sie Zugriff auf die Kamera und laden Sie die Seite neu.
              Danke!
            </h2>
          </div>
          <SadIcon className="smile-icon" width="150" height="150" />
          <div className="slide-buttons">
            <Button variant="contained" onClick={() => setSlide(0)}>
              Startbildschirm
            </Button>
          </div>
        </div>
      </Container>
    );
  }
}
export default Slide6;
