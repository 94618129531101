import React from "react";

function Icon({ width, height, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 1600 1600"
    >
      <circle
        fill="currentColor"
        cx="106.666"
        cy="106.666"
        r="106.666"
        transform="translate(426.667 480)"
      />
      <circle
        fill="currentColor"
        cx="106.667"
        cy="106.667"
        r="106.667"
        transform="translate(960 480)"
      />
      <path
        fill="currentColor"
        d="M-4860-550c-442.666,0-800,357.335-800,800s357.334,800,800,800,800-357.335,800-800S-4417.334-550-4860-550Zm0,1493.335c-384,0-693.335-309.335-693.335-693.335S-5244-443.335-4860-443.335-4166.666-134-4166.666,250-4476,943.335-4860,943.335Z"
        transform="translate(5660 550)"
      />
      <path
        fill="currentColor"
        d="M-5043.288-352.953c-55.359-2.85-100.747,23.91-134.909,79.425-10.5,17.075-30.781,15.51-108.463-8.34-84.524-25.95-200.284-61.485-289.909,36.015l69.935,64.3c25.109-27.315,53.713-35.715,89.084-33.245l-25.271,96.055c-14.7,55.87,18.984,113.615,74.854,128.315h0c55.875,14.7,113.615-18.985,128.315-74.855l26.99-102.565c2.01.125,4.034.325,6.034.385q2.5.075,4.947.075c57.463,0,88.438-30.415,104.394-56.345,28.666-46.575,46.022-46.24,154.685,2.96,24.859,11.255,53.034,24.015,83.584,35.74l34.047-88.69c-27.95-10.73-53.628-22.35-78.447-33.59C-4954.859-330.608-4999.278-350.723-5043.288-352.953Z"
        transform="translate(5993.729 1337.363)"
      />
    </svg>
  );
}

export default Icon;
