import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

export default function ConfirmLeaveChatModal({ show, onHide }) {
  return (
    <div>
      <Dialog
        open={show}
        onClose={onHide}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText>
            Welche Rolle spielst du im Kapitalismus? Finde es heraus im Chat mit
            den Exponaten der Ausstellung „Wir Kapitalisten“ der
            Bundeskunsthalle Bonn. Dafür benötigst du allerdings Kapital. Und
            das gibt es nur gegen deine Emotionen. Also lächle in die Kamera und
            verdiene dir die Ego-Währung! Danach kannst du nach Lust und Laune
            deinen Warenkorb befüllen. Doch aufgepasst: Dein Konsumverhalten
            wird getrackt und ausgewertet. Am Ende erhältst du die Quittung über
            dein kapitalistisches Ich.
            <br />
            <br />
            <strong>Und so wird gespielt:</strong>
            <br />
            <br />
            Verkaufe zunächst deine Emotionen und fülle so deinen Kontostand mit
            der Ego-Währung auf.
            <br />
            <br />
            Wähle dann zwischen den Angeboten und komme mit den Exponaten ins
            Gespräch.
            <br />
            <br />
            Du hast genug gechattet? Dein Konto ist leer? Dann gibt es entweder
            neue Egos im Tausch gegen Emotionen oder du lässt dir die Quittung
            über dein Spielverhalten ausstellen.
            <br />
            <br />
            Mit der Quittung erhältst du Zugang zu einem 360 Grad Video, das auf
            dein persönliches kapitalistisches Profil zugeschnitten ist.
            <br />
            <br />
            <br />
            <br />
            <i>
              Dies ist die Webversion des Ausstellungsspiels KAPITALISMUS GAME,
              das in Kooperation zwischen der Bundeszentrale für politische
              Bildung/bpb und der Kunst- und Ausstellungshalle der
              Bundesrepublik Deutschland, Bonn im Bildungsprogramm zur
              Ausstellung „Wir Kapitalisten. Von Anfang bis Turbo“ realisiert
              wurde. Weitere Infos gibt es hier:
              <br />
              <a href="https://www.bpb.de/WirKapitalisten" target="_blank">
                www.bpb.de/WirKapitalisten
              </a>
              <br />
              <a
                href="https://www.bundeskunsthalle.de/wirkapitalisten"
                target="_blank"
              >
                www.bundeskunsthalle.de/wirkapitalisten
              </a>
            </i>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
