import React, { Component } from "react";

import "../styles/slide10.scss";

import EgosIcon from "./icons/EgosIcon.js";
import BouncingArrow from "./BouncingArrow";
import Container from "react-bootstrap/Container";

class Slide10 extends Component {
  componentDidMount() {
    document.addEventListener("keydown", this.setSlide);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.setSlide);
  }

  setSlide = (e) => {
    if (e.keyCode === 13) this.completeGame()
  };

  completeGame = () => {
    this.props.setSlide(0);
    this.props.onFinished()
  }

  render() {
    return (
      <Container>
        <div className="slides slide-10">
          <h2>
            Herzlichen <br /> Glückwunsch. <br /> Du hast insgesamt <br />
            <div className="egos">
              <div className="egos__number">
                <span>
                  {parseFloat(this.props.allPoints).toLocaleString("de-DE")}{" "}
                </span>{" "}
                Egos
              </div>
            </div>
            verdient.
          </h2>
          <EgosIcon className="egos-icon" width="150" height="150" />
          <div onClick={this.completeGame}>
            <BouncingArrow text="Enter" />
          </div>
        </div>
      </Container>
    );
  }
}

export default Slide10;
