import React from "react";

function Icon({ width, height, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 1600 1600"
    >
      <circle
        fill="currentColor"
        cx="106.667"
        cy="106.667"
        r="106.667"
        transform="translate(426.666 479.999)"
      />
      <circle
        fill="currentColor"
        cx="106.667"
        cy="106.667"
        r="106.667"
        transform="translate(960.001 480)"
      />
      <path
        fill="currentColor"
        d="M-5860-550c-442.672,0-800,357.335-800,800s357.328,800,800,800,800-357.335,800-800S-5417.335-550-5860-550Zm0,1493.335c-384,0-693.335-309.335-693.335-693.335S-6244-443.335-5860-443.335-5166.666-134-5166.666,250-5476,943.335-5860,943.335Z"
        transform="translate(6660 550)"
      />
      <rect
        fill="currentColor"
        width="635.332"
        height="97.827"
        transform="translate(485 1027.507)"
      />
    </svg>
  );
}

export default Icon;
