import React, { Component } from "react";

import "../styles/slide3.scss";
import HappyIcon from "./icons/HappyIcon.js";
import SadIcon from "./icons/SadIcon.js";
import NeutralIcon from "./icons/NeutralIcon.js";
import BouncingArrow from "./BouncingArrow";
import SurprizedIcon from "./icons/SurprizedIcon";
import DisgustedIcon from "./icons/DisgustedIcon";
import ScaredIcon from "./icons/ScaredIcon";
import AngryIcon from "./icons/AngryIcon";
import Container from "react-bootstrap/Container";

class Slide3 extends Component {
  componentDidMount() {
    document.addEventListener("keydown", this.setSlide);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.setSlide);
  }

  setSlide = (e) => {
    if (e.keyCode === 13) this.props.setSlide(4);
  };

  get Icon() {
    let Icon = null;

    switch (this.props.emotion) {
      case "happy":
        Icon = HappyIcon;
        break;
      case "sad":
        Icon = SadIcon;
        break;
      case "neutral":
        Icon = NeutralIcon;
        break;
      case "surprised":
        Icon = SurprizedIcon;
        break;
      case "fearful":
        Icon = ScaredIcon;
        break;
      case "disgusted":
        Icon = DisgustedIcon;
        break;
      case "angry":
        Icon = AngryIcon;
        break;
      default:
        Icon = NeutralIcon;
        break;
    }
    return Icon;
  }

  get text() {
    switch (this.props.emotion) {
      case "happy":
        return "glücklich";
      case "sad":
        return "unglücklich";
      case "neutral":
        return "neutral";
      case "angry":
        return "wütend";
      case "surprised":
        return "überrascht";
      case "fearful":
        return "ängstlich";
      case "disgusted":
        return "angeekelt";
      default:
        return null;
    }
  }

  render() {
    const { Icon } = this;
    const { setSlide } = this.props;
    
    return (
      <Container>
        <div className="slides slide-3">
          <div>
            <h2>
              Dein Gesicht wird <br />
              analysiert. Spiele
              <br /> jetzt
              <span> »{this.text}«</span> <br />
            </h2>
            <h2>Bereit?</h2>
          </div>

          <Icon className="smile-icon" />
          <div onClick={() => setSlide(4)}>
            <BouncingArrow text="Enter" />
          </div>
        </div>
      </Container>
    );
  }
}

export default Slide3;
