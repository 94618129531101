export const chat = `0 Jetzt liege ich am Boden... 😢 -> 1
1 Abgestürzt. Eine volle Bruchlandung. 😖 -> 2
2 Was für ein Höhenflug. Mein Traum, ich ganz oben! -> 3
3 Einmal "bei den Göttern". Dafür hat es sich gelohnt. Auch das Fallen. -> 4
4 Bist du schon mal geflogen? [5,6]
5 Ja, natürlich - mit dem Flugzeug. -> 8
6 Nein, noch nie... -> 7 {a1+1}
7 Ungewöhnlich. Du solltest es mal versuchen! Also, wenn man wieder darf... -> 12
8 Fliegst du sonst viel? [9,10,11]
9 Nein, nur sehr selten. -> 12 {a1+1}
10 1-3 mal im Jahr. -> 12 {a1+0}
11 Mehr als 3 mal im Jahr. -> 12 {a1-1}
12 Vielleicht ja bald mal wieder -> 13
13 Und guck mal, ich rappele mich schon wieder auf. 🙂 -> 14
14 Nicht, dass du noch einen falschen Eindruck bekommst. 😉 -> 15
15 Es schmerzt noch ziemlich... Doch nur wer alles gibt, die richtige Einstellung hat... -> 16
16 ... -> 17
17 Mein Motto: Ich kann alles schaffen, wenn ich nur daran glaube [18,19,20]
18 Gutes Motto! -> 22 {a0-1}
19 Yes! nur die Stärksten setzen sich durch. -> 23 {a0-1}
20 Das ist ein bisschen naiv... -> 21 {a0+1}
21 Ach wirklich? Gut, die Diskussion hatte ich schon öfter... darauf lasse ich mich gar nicht ein... ich möchte deine und meine Zeit nicht weiter verschwenden... -> 22
22 Lass uns das Gespräch beenden. -> 25
23 Du also auch? Die Gewinner-Typen ziehen sich wie magisch an. 🙂 -> 24
24 Aber jetzt will ich es erneut versuchen. -> 25
25 Ich heb' dann mal wieder ab! -> 26
26 * Über den Wolken ... * -> 101

101 ||Exit
`