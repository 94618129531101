import React, { Component } from "react";

import "../styles/slide8.scss";

import HappyIcon from "./icons/HappyIcon.js";
import SadIcon from "./icons/SadIcon.js";
import NeutralIcon from "./icons/NeutralIcon.js";
import BouncingArrow from "./BouncingArrow";
import SurprizedIcon from "./icons/SurprizedIcon";
import DisgustedIcon from "./icons/DisgustedIcon";
import ScaredIcon from "./icons/ScaredIcon";
import AngryIcon from "./icons/AngryIcon";
import Container from "react-bootstrap/Container";

class Slide8 extends Component {
  state = { points: 0 };

  componentDidMount() {
    this.setState({ points: Math.round(this.props.points.score * 10) });
    document.addEventListener("keydown", this.setNextEmotion);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.setNextEmotion);
  }

  setNextEmotion = (e) => {
    if (e.keyCode === 13) this.props.setNextEmotion();
  };

  get Icon() {
    let Icon = null;

    switch (this.props.emotion) {
      case "happy":
        Icon = HappyIcon;
        break;
      case "sad":
        Icon = SadIcon;
        break;
      case "neutral":
        Icon = NeutralIcon;
        break;
      case "surprised":
        Icon = SurprizedIcon;
        break;
      case "fearful":
        Icon = ScaredIcon;
        break;
      case "disgusted":
        Icon = DisgustedIcon;
        break;
      case "angry":
        Icon = AngryIcon;
        break;
      default:
        Icon = NeutralIcon;
        break;
    }
    return Icon;
  }

  get text() {
    switch (this.props.emotion) {
      case "happy":
        return "glücklich";
      case "sad":
        return "unglücklich";
      case "neutral":
        return "neutral";
      case "angry":
        return "wütend";
      case "surprised":
        return "überrascht";
      case "fearful":
        return "ängstlich";
      case "disgusted":
        return "angeekelt";
      default:
        return null;
    }
  }

  get points() {
    return this.state.points === -Infinity ? 0 : this.state.points;
  }

  get yourPoints() {
    const { Icon } = this;
    return new Array(this.points)
      .fill()
      .map((_, index) => (
        <Icon className="icons__item" width="60" height="60" key={index} />
      ));
  }

  get restPoints() {
    const { Icon } = this;

    return new Array(10 - this.points)
      .fill()
      .map((_, index) => (
        <Icon
          className="icons__item icons__item--violet"
          width="60"
          height="60"
          key={index}
        />
      ));
  }

  render() {
    const { setNextEmotion } = this.props;
    return (
      <Container>
        <div className="slides slide-8">
          <h2>
            Du hast „{this.text}“ <br /> mit einer Intensität von <br />
            <div className="result">
              <div className="result__your-point">{this.points}</div>
              <div className="result__all-point">/10</div>
            </div>
            dargestellt.
          </h2>
          <div className="icons">
            {this.yourPoints}
            {this.restPoints}
          </div>
          <div onClick={() => setNextEmotion()}>
            <BouncingArrow text="Enter" />
          </div>
        </div>
      </Container>
    );
  }
}

export default Slide8;
