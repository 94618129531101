export const chat = `0 Wie schön, dass du endlich persönlich kommst, um mich anzubeten [1, 2]
1 Wieso anbeten, ich schaue dich doch nur an? -> 3
2 Ja, ich freue mich auch mein Herr und Herrscher. -> 37
3 Haha, du betest mich immer an, ob du es weißt oder nicht. [4,5]
4 Verstehe ich jetzt nicht. -> 6
5 Ich bete dich nicht an, nicht jetzt und auch sonst nicht. -> 6
6 Hahahahah. Haha. HAHAH. HARRR. [7,8]
7 Wieso lachst du so blöd? -> 9
8 Ich finde das nicht witzig. -> 9
9 Entweder tust du so blöd oder du bist es wirklich. -> 10
10 Ich bin Mammon, das Geld, Kohle, Asche, Moneten -> 11
11 Und ich habe die ganze Welt in meinen Spielplatz verwandelt. -> 12
12 Ein globales Fangen-Spiel. Alle laufen hinter mir her, aber sie werden mich nicht kriegen. Hahaha. [13, 14, 15]
13 Ich laufe nicht hinter dir her. -> 16
14 Ich glaube, du überschätzt dich ein bisschen. -> 16
15 Ich fand Geld noch nie wichtig. -> 16
16 Egal was du denkst oder tust - du bist Teil meines Systems. Ich habe alles mammonisiert! -> 17
17 Deshalb bin ich so krass gut drauf. Ich habe alles und jeden eingesackt in meinen Kosmos. [18, 19]
18 Ich bin vielleicht nicht perfekt, aber ich fröne nicht dem Mammon -> 20
19 Man kann auch ein richtiges Leben im falschen System führen. -> 20
20 Klar, man auch so naiv wie ein Toastbrot sein. -> 21
21 Egal, was du tust, du betest mich immer an. -> 22
22 Selbst wenn du der superalternative Ökotyp sein solltest - in deinem Namen werden Kinder ausgebeutet und ganze Länder in die Armut gestürzt. -> 23
23 Mit deinem Geld und deinen Gefühlen erhält sich ein System, dem Geld über alles geht.. Herrlich! [24, 25]
24 Ich glaube, du siehst das alles viel zu negativ. -> 26
25 Und was soll man machen? -> 29
26 Ja. Hahaha. Fantastisch!! Kann es einen größeren Beweis geben für meine Allmacht? -> 27
27 Die besten Diener des Mammons, sind jene, die ihn ignorieren. -> 28
28 Du bist ein guter Sklave. Mach weiter so. -> 101
29 Das ist doch wohl klar: Mehr Geld. -> 30
30 Mach mehr Geld. -> 31
31 Geld ist Macht. -> 32
32 Und Macht macht Spaß. -> 33
33 Also: Bejahe, was du nicht ändern kannst und "mammonisier" dich. [34, 35, 36]
34 Hmm, ich denke noch mal drüber nach. -> 101
35 Ich glaube, ich verdünnisiere mich erst einmal. -> 101
36 Du hast mich überzeugt. Mammon - du bist wirklich der Größte! -> 37
37 Bin ich wirklich dein einziger Gott oder flunkerst du? [38, 39]
38 Naja, ich dachte ich probiere es mal. -> 40
39 Ja, du bist mein einziger Gott. -> 41
40 Frech bist du. Aber dennoch - ich bin dein Gott. [4,5]
41 Weißt du auch was das bedeutet? [42,43, 44]
42 Ich glaube ja. -> 45
43 Fast, naja, also - ähh. Nein. -> 45
44 Ich bin nicht ganz sicher. -> 45
45 Das bedeutet, dass dir Geld wichtiger ist als Familie. -> 46
46 Ist das so? [47, 48]
47 Ja -> 49
48 Nein -> 50
49 Sehr gut!! -> 51
50 Hmm. Schade. Ich dachte ich hätte eine reine Seele gefunden. Aber… -> 16
51 Das bedeutet auch, dass Geld dir wichtiger ist als Liebe. -> 52
52 Ist das so? [53, 54]
53 Ja -> 55
54 Nein -> 57
55 Fantastisch!! -> 56
56 Du hast es echt drauf! -> 58
57 Hmm. Schade. Ich dachte ich hätte eine reine Seele gefunden. Aber… -> 16
58 Wenn du mein Jünger werden willst,... -> 59
59 dann musst du dem Profit alles unterordnen -> 60
60 Mehr Geld, mehr Potenz, mehr Macht -> 61
61 Das sollen deine einzigen Leitlinien sein -> 62
62 Du sollst keine Moral neben dem Mammon haben -> 63
63 Willst du mich lieben und ehren? [64, 65, 66]
64 Ja, ich will! -> 67
65 Ich bin jetzt doch ein bisschen unsicher geworden gerade. -> 67
66 Nein, ich habe dich verarscht du Spinner! -> 67
67 Ach wie schön! -> 68
68 Willkommen in der Beutegemeinschaft der Mammonaten. [69, 70, 71]
69 Ich werde dir dienen und alles machen, was du willst. -> 72
70 Mir wird das alles zu viel. -> 72
71 Ey Digga, komm mal klar und lass mich aus dem Chat! -> 72
72 Ich werde meine Hand über dich halten -> 73
73 Gemeinsam werden wir die Welt erobern -> 74
74 Möge meine Potenz deinen Reichtum beflügeln -> 75
75 Und deine Lenden kräftigen. -> 76
76 Willkommen im Club. -> 101

101 ||Exit
`