import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import DatenschutzDetailsModal from "./DatenschutzDetailsModal";
import Link from "@material-ui/core/Link";

import "./styles/AcceptModal.scss"

export default function AcceptModal(props) {
  const { isModalOpen, setUserDecision } = props;
  const [open, setOpen] = React.useState(isModalOpen);
  const [state, setState] = React.useState({
    isCookiesChecked: false,
    isVideoChecked: false,
    isDatenschutzModal: false,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleClose = (isUserAccept) => {
    const decision = {
      isAccept: isUserAccept,
      isUserAcceptVideo: state.isVideoChecked,
    };
    localStorage.setItem("decision", JSON.stringify(decision));
    setUserDecision(isUserAccept);
    setOpen(false);
  };

  const changeWindowState = () =>
    setState({ isDatenschutzModal: !state.isDatenschutzModal });

  return (
    <div>
      <Dialog
        disableBackdropClick
        open={open}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className="acceptModal"
      >
        <DialogTitle id="scroll-dialog-title">
          Einwilligung Datenverarbeitung
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <p>
              Im Rahmen des Browserspiels „Das Kapitalismus Game“ werden Daten
              nur zur Durchführung des digitalen Spiels verarbeitet und nicht an
              Dritte weitergegeben. Es findet keine Speicherung
              personenbezogener Daten statt.
            </p>
            <br />
            <p>
              Teil des Spiels ist eine Analyse Ihres Gesichtsausdrucks über die
              Kamera des Computers bzw. Smartphones zum Aufladen der
              Spielwährung „Egos“. Alle Kameraaufnahmen dienen ausschließlich
              der Durchführung des Spiels und werden nicht dauerhaft
              gespeichert. Die Porträts, die von den Nutzer/-innen des Spiels
              aufgenommen werden, werden einer Skala von 1 bis 10 zugewiesen.
              Dieser Wert ist der einzige, der im Verlauf des Spiels
              weiterverwendet wird. Die Porträtaufnahmen werden unmittelbar nach
              ihrer Zuordnung zu diesem Wert wieder gelöscht.
            </p>
            <br />
            <p>
              Grundsätzlich werden auch alle weiteren Daten nur im unmittelbaren
              Moment der Verarbeitung im Speicher gehalten und darüber hinaus
              nicht weiter gespeichert. Die entstandene Auswertung kann Ihrer
              Person nicht zugeordnet werden.
            </p>
            <br />
            <p>
              Zur Speicherung des Spielstandes setzt das Spiel (mit
              entsprechenderZustimmung) einen Cookie auf Ihrem Rechner. Dieser
              Cookie enthält nur den anonymen Spielstand, sodass das Spiel auch
              über mehrere Browser-Sessions hinweg fortgesetzt werden kann.
            </p>
            <br />
            <p>
              Im letzten Teil des Spiels wird der Videoplayer des externen
              Google-Dienstes YouTube auf der Seite des „Kapitalismus Games“
              eingebunden. Das Spiel nutzt diesen Dienst, um den Nutzer/-innen
              dieNutzung eines 360 Grad-Video-Players im Spielverlauf zu
              ermöglichen.Dabei kann eine Datenübermittlung von der Seite des
              „Kapitalismus Games“ zu dem Google-Dienst YouTube stattfinden und
              es können Cookies durch diesen gesetzt werden. Die Kontrolle über
              die Datenübermittlung ist nur möglich, wenn die Nutzenden der
              Einbindung des YouTube-Players über den Aktivierungskasten aktiv
              zugestimmt haben. Solange die Nutzer/-innen nicht aktiv
              einwilligen, sich den externen Inhalt anzeigen zu lassen, findet
              keine Datenübermittlung statt und der Inhalt wird nicht
              ausgespielt. Im Rahmen der Einbindung des Players gelten die dort
              aufgeführten Datenschutzhinweise unter
              <a
                href="https://policies.google.com/privacy?hl=de"
                target="_blank"
              >
                {" "}
                https://policies.google.com/privacy?hl=de
              </a>
            </p>
            <br />
            <p>Weitere Informationen finden Sie unter der</p>
              <Link
                component="button"
                variant="body1"
                underline="always"
                onClick={changeWindowState}
                className="dialog-link"
              >
                DATENSCHUTZERKLÄRUNG
              </Link>
            <br />
            <br />
            <DatenschutzDetailsModal
              show={state.isDatenschutzModal}
              onHide={changeWindowState}
            />
          </DialogContentText>
          <DialogActions className="dialog-actions">
            <FormControl required component="fieldset">
              <FormGroup column>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.isCookiesChecked}
                      onChange={handleChange}
                      name="isCookiesChecked"
                      color="primary"
                    />
                  }
                  label="Erlaube die Setzung des Cookies"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.isVideoChecked}
                      onChange={handleChange}
                      name="isVideoChecked"
                      color="primary"
                    />
                  }
                  label="Erlaube die Einbindung des Youtube-Players"
                />
              </FormGroup>
            </FormControl>
          </DialogActions>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => handleClose(true)}
              color="primary"
              disabled={!state.isCookiesChecked}
            >
              Speichern
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}
