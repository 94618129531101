import React, { Component } from "react";
import * as faceapi from "face-api.js";

import "../styles/slide5.scss";
import HappyIcon from "./icons/HappyIcon.js";
import SadIcon from "./icons/SadIcon.js";
import NeutralIcon from "./icons/NeutralIcon.js";
import SurprizedIcon from "./icons/SurprizedIcon";
import DisgustedIcon from "./icons/DisgustedIcon";
import ScaredIcon from "./icons/ScaredIcon";
import AngryIcon from "./icons/AngryIcon";
import Container from "react-bootstrap/Container";

const constrains = {
  audio: false,
  video: { width: 1280, height: 720 },
};

class Slide5 extends Component {
  state = { rec: false, counter: 3, counting: false, isCamera: false };

  recognisedEmotions = [];

  async componentDidMount() {
    await navigator.mediaDevices
      .getUserMedia(constrains)
      .then((stream) => {
        this.setState({ isCamera: true });
        this.stream = stream;
        this.video.srcObject = this.stream;
        this.setState({ counting: true });
        this.countDown();
        setTimeout(this.captureEmotions, 3 * 1000);
        this.startRecognitionInterval(this.onNext, this.onComplete);
      })
      .catch((err) => {
        console.log(err.name);
        if (err.name === "NotAllowedError") {
          this.setState({ isCamera: false });
          this.props.setSlide(0);
        } else {
          this.setState({ isCamera: false });
          this.props.setSlide(3);
        }
      });
  }

  componentWillUnmount() {
    if (this.state.isCamera) {
      this.stream.getTracks().forEach((track) => track.stop());
    }
  }

  captureEmotions = () => {
    if (!this.state.rec) {
      this.intervalStartTime = Date.now();
      this.setState({ rec: true });
    }
  };

  countDown = () => {
    setTimeout(() => {
      const counter = this.state.counter - 1;

      if (counter !== 0) {
        this.setState({ counter });
        this.countDown();
      }
    }, 1000);
  };

  onNext = async () => {
    if (!this.video) return;

    try {
      const recognitions = await faceapi
        .detectSingleFace(
          this.video,
          new faceapi.TinyFaceDetectorOptions(224, 0.5)
        )
        .withFaceExpressions();

      if (!!recognitions && this.state.rec) {
        this.drawRecognitions(recognitions);
        this.recognisedEmotions.push(
          recognitions.expressions[this.props.emotion]
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  onComplete = () => {
    this.props.setRecognisedEmotion(
      this.calculateScoreFor(this.props.emotion, this.recognisedEmotions)
    );
    this.props.setSlide(6);
  };

  startRecognitionInterval = (onNext, onComplete) => {
    let isCompleted = false;
    this.interval = setInterval(async () => {
      if (Date.now() - this.intervalStartTime >= 5000 && !isCompleted) {
        isCompleted = true;
        clearInterval(this.interval);
        onComplete();
      } else {
        onNext();
      }
    }, 100);
  };

  drawRecognitions = (recognitions) => {
    const resizedBox = faceapi.resizeResults(
      recognitions,
      faceapi.matchDimensions(this.canvas, this.video, true)
    );
    this.canvas
      .getContext("2d")
      .clearRect(0, 0, this.canvas.width, this.canvas.height);
    faceapi.draw.drawFaceExpressions(this.canvas, resizedBox, 0.01);
    faceapi.draw.drawDetections(this.canvas, resizedBox);
  };

  calculateScoreFor(emotion, recognisedEmotions) {
    return {
      emotion: emotion,
      score: Math.max(...recognisedEmotions),
    };
  }

  get Icon() {
    let Icon = null;

    switch (this.props.emotion) {
      case "happy":
        Icon = HappyIcon;
        break;
      case "sad":
        Icon = SadIcon;
        break;
      case "neutral":
        Icon = NeutralIcon;
        break;
      case "surprised":
        Icon = SurprizedIcon;
        break;
      case "fearful":
        Icon = ScaredIcon;
        break;
      case "disgusted":
        Icon = DisgustedIcon;
        break;
      case "angry":
        Icon = AngryIcon;
        break;
      default:
        Icon = NeutralIcon;
        break;
    }
    return Icon;
  }

  get text() {
    switch (this.props.emotion) {
      case "happy":
        return "glücklich";
      case "sad":
        return "unglücklich";
      case "neutral":
        return "neutral";
      case "angry":
        return "wütend";
      case "surprised":
        return "überrascht";
      case "fearful":
        return "ängstlich";
      case "disgusted":
        return "angeekelt";
      default:
        return null;
    }
  }

  render() {
    const { Icon } = this;
    const { isCamera } = this.state;

    return (
      <Container>
        {isCamera && (
          <div className="slides slide-5">
            <h2>Spiele »{this.text}«</h2>
            <Icon className="small-happy-icon" width="150" height="150" />
            <div className="video-container">
              <div className="video-container__stick"></div>
              <div className="video-container__stick"></div>
              <div className="video-container__stick"></div>
              <div className="video-container__stick"></div>
              <canvas
                ref={(canvas) => (this.canvas = canvas)}
                className="canvas"
              ></canvas>
              <Icon className="big-happy-icon" width="210" height="210" />
              <video
                ref={(video) => (this.video = video)}
                className="video"
                playsInline
                autoPlay
              ></video>
              {this.state.rec && (
                <div className="rec">
                  <div className="rec__circle"></div>
                  <div className="rec__text">REC</div>
                </div>
              )}
            </div>
            {this.state.rec && (
              <div className="progress-bar">
                <div className="progress-bar__line"></div>
              </div>
            )}
            {!this.state.rec && this.state.counting && (
              <div className="count-down">
                <div className="count-down__number">{this.state.counter}</div>
              </div>
            )}
          </div>
        )}
      </Container>
    );
  }
}

export default Slide5;
