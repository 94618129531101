import React, { Component } from "react";

import "../styles/slide9.scss";

import EgosIcon from "./icons/EgosIcon.js";
import Container from "react-bootstrap/Container";

class Slide9 extends Component {
  componentDidMount() {
    setTimeout(() => this.props.setSlide(9), 3000);
  }

  render() {
    return (
      <Container>
        <div className="slides slide-9">
          <h2>
            Deine Punkte <br /> werden <br /> zusammengezählt. <br /> Die
            Ego-Währung <br /> wird berechnet.
          </h2>
          <EgosIcon className="egos-icon" width="150" height="150" />
        </div>
      </Container>
    );
  }
}

export default Slide9;
