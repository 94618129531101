export const chat = `0 Ring -> 1
1 Ring Rinnnnggg!!!! -> 2
2 Na, du hast aber ganz schön was geblecht, um mit mir zu sprechen -> 3
3 Da ist wohl eine*r neugierig, was? -> 4
4 Kann ich gut verstehen -> 5
5 Die Menschen haben so einiges auf sich genommen, um an meine Nummer zu kommen -> 6
6 Ich war der Draht zur Macht 📞 -> 7
7 Und ich habe viiieeel mitbekommen 🤐 -> 8
8 Was meinst du, was habe ich so alles mitgehört? [9,10,11]
9 Verschwörungspläne -> 12
10 Bestechungsangebote -> 12
11 Erpressungen -> 12
12 Hahaaaaa, etwas arg sensationslustig, was?  -> 13
13 Aber klar, mein Wert wird bestimmt durch das, was die Leute mir zuschreiben. -> 14
14 Oder sich ausmalen. 🤔 -> 15
15 Was sein könnte. 🤯 -> 16
16 🙃🙃🙃 -> 17
17 Dabei bin ich eigentlich nur eine Schnittstelle. Ein Gerät, das Menschen verbindet. -> 18
18 Meinst du ich habe noch etwas von Frau Merkels Aura an mir?  [19,20]
19 Wenn ich dich ansehe, spüre ich die Macht -> 21 {a1-1}
20 Du bist nur ein Stück Elektroschrott 🚮 -> 22 {a1+1}
21 Ja, durch mich ist viel Macht geflossen. Wenn man es weiß, spürt man es auch. 🤑 -> 23
22 Tja, heute leider schon, aber früher konnte ich mit meinen Funktionen überzeugen -> 23
23 Wer weiß, vielleicht bin ich ja nur irgendein S55 Handy? -> 24
24 Und ich habe mich hier nur wichtig gemacht? -> 25
25 So ein Museumsschild neben mir macht schon was aus -> 26
26 Was, wenn da stände, ich sei David Bowies Handy? Was denkst du, wäre ich wert? [27,28,29]
27 10.000 € -> 30 {a1+1}
28 100.000 € -> 30
29 1.000.000 € -> 30 {a1-1}
30 Tja, könnte schon sein. Vielleicht auch noch mehr. 💰💰💰 -> 31
31 Wert ist eine ganz schön merkwürdige Sache. 👻 -> 32
32 Vor ein paar Jahren wurde für einen Kaugummi, den Britney Spears im Mund hatte, 12.700 € bezahlt. -> 33
33 Muss über die Maßen lecker gewesen sein... 🤢 -> 34
34 Wie ist es mit dir? -> 35
35 Was bist du eigentlich wert? [36,37,38]
36 So viel wie das Handy, das ich in der Hand halte -> 39
37 So viel wie jeder andere Mensch -> 39
38 Mehr als alles andere auf der Welt -> 39
39 Uff, tja, das mag für die ein oder andere Person stimmen. -> 40
40 Für viele andere nicht. -> 41
41 Wenn ich da so an die ganzen Gespräche denke, die ich mitgehört habe... -> 42
42 Ich bin auf jeden Fall stolz auf mein Museumsschild. 💬 -> 43
43 Es belegt meinen Wert. 💸 -> 44
44 Und weißt du was? [45,46]
45 Nein, was denn? -> 47
46 Ich weiß schon... -> 47
47 Das Gespräch mit dir, das hat Wert für mich gehabt. -> 48
48 Du bist zwar nicht Angela, aber ein genauso einzigartiger Mensch. -> 49
49 Ich wünsche dir ganz viel... wertvolle... Eindrücke. -> 50
50 Und Einsichten. -> 51
51 Ring! Ring! -> 120

120 ||Exit
`