import React, { Component } from "react";
import * as faceapi from "face-api.js";
import Slide1 from "./Slide1";
import Slide2 from "./Slide2";
import Slide3 from "./Slide3";
import Slide4 from "./Slide4";
import Slide5 from "./Slide5";
import Slide6 from "./Slide6";
import Slide7 from "./Slide7";
import Slide8 from "./Slide8";
import Slide9 from "./Slide9";
import Slide10 from "./Slide10";
import Slide11 from "./slide11/Slide11";
import Slide12 from "./Slide12";
import Slide13 from "./Slide13";
import AcceptModal from './modals/AcceptModal'

const EMOTIONS = ["happy", "sad", "neutral", "angry", "surprised", "fearful", "disgusted"];

class App extends Component {
  state = {};

  constructor() {
    super();
    let emotionsForRecognition = this.pickRandomElementsFromArray(EMOTIONS, 3);
    this.state = {
      slide: 0,
      emotion: emotionsForRecognition[0],
      emotionsForRecognition: emotionsForRecognition,
      recognisedEmotions: [],
      egos: this.dataStorage?.egos || 0,
      characteristics: this.characteristicsStorage || {
        a0: null,
        a1: null,
        a2: null,
        a3: null,
        a4: null,
      },
      userInfo: {
        isUserTookEgos: this.dataStorage?.tookEgos || false,
        isUserAccept: this.userDecisionStorage?.isAccept || false
      },
      receiptVideoLink: '',
    };
  }

  componentDidMount() {
    this.loadModels();
  }

  onSelectedChat(selectedChat) {
    this.setState({ selectedChat });
    this.setSlide(1);
  }

  onConfirmChat = () => {
    const { egos, selectedChat } = this.state;
    this.setState({
      egos: egos - selectedChat.cost,
    });
    this.setSlide(10);
    const data = {
      ...this.dataStorage,
      egos: egos - selectedChat.cost
    };
    localStorage.setItem('info', JSON.stringify(data))
  };

  loadModels() {
    Promise.all([
      faceapi.nets.tinyFaceDetector.loadFromUri("/models"),
      faceapi.nets.faceExpressionNet.loadFromUri("/models"),
    ]);
  }

  get points() {
    return this.state.recognisedEmotions.find((it) => it.emotion === this.state.emotion);
  }

  get dataStorage() {
   return JSON.parse(localStorage.getItem('info'));
  }

  get characteristicsStorage() {
   return JSON.parse(localStorage.getItem('characteristics'));
  }

  get userDecisionStorage() {
   return JSON.parse(localStorage.getItem('decision'));
  }

  get allPoints() {
    const allPoints = this.state.recognisedEmotions.reduce((accumulator, currentValue) => {
      const score = currentValue.score === -Infinity ? 0 : currentValue.score;

      return accumulator + Math.round(score * 10);
    }, 0);

    const min = 3;
    const max = 30;

    const delta = allPoints - min;
    const score = (delta / (max - min)) * 30000;
    const roundedScore = Math.floor(score);

    return 30000 + Math.round(roundedScore / 100) * 100;
  }

  get slides() {
    const {
      egos,
      selectedChat,
      emotion,
      characteristics,
      userInfo,
      receiptVideoLink,
    } = this.state;

    return [
      <Slide1
        setSlide={this.setSlide}
        onSelectedChat={(chat) => this.onSelectedChat(chat)}
        egos={egos}
        characteristics={characteristics}
        userInfo={userInfo}
      />,
      <Slide2
        setSlide={this.setSlide}
        chat={selectedChat}
        onConfirmChat={this.onConfirmChat}
      />,
      <Slide3 emotion={emotion} setSlide={this.setSlide} />,
      <Slide4
        setSlide={this.setSlide}
        takeEgosOnce={this.takeEgosOnce}
        isUserTookEgos={userInfo?.isUserTookEgos}
      />,
      <Slide5
        emotion={emotion}
        setSlide={this.setSlide}
        setRecognisedEmotion={this.setRecognisedEmotion}
      />,
      <Slide6
        setSlide={this.setSlide}
        characteristics={characteristics}
      />,
      <Slide7 emotion={emotion} setSlide={this.setSlide} />,
      <Slide8
        emotion={emotion}
        setSlide={this.setSlide}
        points={this.points}
        setNextEmotion={this.setNextEmotion}
      />,
      <Slide9 emotion={emotion} setSlide={this.setSlide} />,
      <Slide10
        emotion={emotion}
        setSlide={this.setSlide}
        allPoints={this.allPoints}
        onFinished={this.onGameFinished}
      />,
      <Slide11
        setSlide={this.setSlide}
        chatID={selectedChat?.id}
        changeCharacteristics={(characteristics) =>
          this.changeCharacteristics(characteristics)
        }
        characteristics={characteristics}
      />,
      <Slide12
        setSlide={this.setSlide}
        characteristics={characteristics}
        setReceiptVideo={video => this.setReceiptVideo(video)}
      />,
      <Slide13
        setSlide={this.setSlide}
        receiptVideoLink={receiptVideoLink}
      />,
    ];
  }

  get slide() {
    return this.slides[this.state.slide];
  }

  pickRandomElementsFromArray = (array, count) => {
    let results = [];
    for (let i = 0; i < count; i++) {
      let picked;
      do {
        picked = array[Math.floor(Math.random() * array.length)];
      } while (results.includes(picked));
      results.push(picked);
    }
    return results;
  };

  setRecognisedEmotion = (emotion) => {
    let recognisedEmotions = [...this.state.recognisedEmotions];
    const score = Math.floor((emotion.score === -Infinity ? 0 : emotion.score) * 10);

    if (score === 0) {
      emotion.score = this.pickRandomElementsFromArray([0.1, 0.2], 1)[0];
    }

    recognisedEmotions.push(emotion);
    this.setState({ recognisedEmotions });
  };

  setSlide = (slide) => {
    if (slide >= this.slides.length) {
      this.setState({ slide: 0 });
      return;
    }

    this.setState({ slide });
  };

  setNextEmotion = async () => {
    const { emotionsForRecognition, emotion } = this.state;
    const index = emotionsForRecognition.findIndex(it => emotion === it);
    const isLast = index === 2;
    if (isLast) {
      this.setSlide(8);
      let emotionsForRecognition = this.pickRandomElementsFromArray(
        EMOTIONS,
        3
      );
      this.setState({
        emotionsForRecognition: emotionsForRecognition,
        emotion: emotionsForRecognition[0]
      });
      return;
    } else {
      this.setState({ emotion: emotionsForRecognition[index + 1] });
      this.setSlide(2);
    }
  }

  takeEgosOnce = (egos) => {
    const info = {
      egos: this.state.egos + egos, 
      tookEgos: true
    };
    localStorage.setItem('info', JSON.stringify(info));
    this.setState({
      egos: this.state.egos + egos,
      userInfo: {
        ...this.state.userInfo,
        isUserTookEgos: true,
      },
    });
   
  };

  setReceiptVideo = (receiptVideoLink) => this.setState({ receiptVideoLink });

  onGameFinished = () => {
    let emotionsForRecognition = this.pickRandomElementsFromArray(EMOTIONS, 3);
    const data = {
      ...this.dataStorage,
      egos: this.state.egos + this.allPoints
    };
    localStorage.setItem('info', JSON.stringify(data))

    this.setState({
      emotion: emotionsForRecognition[0],
      emotionsForRecognition: emotionsForRecognition,
      recognisedEmotions: [],
      egos: this.dataStorage.egos 
    });
    
  };

  changeCharacteristics(action) {
    const { variable, operation, value } = action;

    switch (operation) {
      case "+":
        this.setState((prevState) => ({
          characteristics: {
            ...prevState.characteristics,
            [variable]: prevState.characteristics[variable] + +value,
          },
        }));
        break;

      case "-":
        this.setState((prevState) => ({
          characteristics: {
            ...prevState.characteristics,
            [variable]: prevState.characteristics[variable] - +value,
          },
        }));
        break;

      default:
        break;
    }
  }

  setUserDecision = (isUserAccept) => {
    this.setState({
      userInfo: {
        ...this.state.userInfo,
        isUserAccept
      },
    });
  }

  render() {
    const { userInfo } = this.state
    return (
      <>
        <div className="App">{this.slide}</div>
        <AcceptModal
          isModalOpen={!userInfo.isUserAccept}
          setUserDecision={(decision) =>
            this.setUserDecision(decision)
          }
        />
      </>
    );
  }
}

export default App;
