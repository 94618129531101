import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import "./styles/DatenschutzDetailsModal.scss"

export default function DatenschutzDetailsModal(props) {
  const { show, onHide } = props;

  return (
    <div>
      <Dialog
        open={show}
        onClose={onHide}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        scroll="paper"
        className="datenschutzDetailsModal"
      >
        <DialogTitle id="scroll-dialog-title" className="dialog-title" >
          <IconButton aria-label="close" onClick={onHide}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <h2>Datenschutzerkl&auml;rung f&uuml;r das Kapitalismus Game</h2>
            <h3>gem&auml;&szlig; Art. 13 DSGVO</h3>
            <p>
              <em>Stand: 30.07.2020</em>
            </p>
            <h3 className="dialog-h3">
              A. Kontaktdaten und Verantwortliche
            </h3>
            <p>
              <strong>1. Name und Anschrift des Verantwortlichen</strong>
              <br />
              Verantwortliche im Sinne der Datenschutz-Grundverordnung und
              anderer nationaler Datenschutzgesetze der Mitgliedsstaaten sowie
              sonstiger datenschutzrechtlicher Bestimmungen ist die:
              <br />
              <br />
              Bundeszentrale f&uuml;r politische Bildung
              <br />
              Adenauerallee 86
              <br />
              53115 Bonn
              <br />
              Deutschland
              <br />
              Tel.: +49 (0)228 99 515-0
              <br />
              E-Mail:{" "}
              <a href="mailto:info@bpb.de" target="_blank">
                info@bpb.de{" "}
              </a>
              <br />
              DE-Mail:{" "}
              <a
                href="mailto:de-mail-poststelle@bpb-bund.de-mail.de"
                target="_blank"
              >
                de-mail-poststelle@bpb-bund.de-mail.de
              </a>{" "}
              <br />
              Website:{" "}
              <a href="https://www.bpb.de/" target="_blank">
                www.bpb.de
              </a>{" "}
              <br />
              <br />
              <strong>
                2. Name und Anschrift des Datenschutzbeauftragten der bpb
              </strong>
              <br />
              Der Datenschutzbeauftragte der Verantwortlichen ist:
              <br />
              <br />
              Rhaban Schulze Horn
              <br />
              Adenauerallee 131a
              <br />
              53113 Bonn
              <br />
              Deutschland
              <br />
              Tel.: +49 (0)228 99 515-0
              <br />
              E-Mail:{" "}
              <a href="mailto:datenschutz@bpb.de" target="_blank">
                datenschutz@bpb.de
              </a>
            </p>
            <h3 className="dialog-h3">
              B. Allgemeines zur Datenverarbeitung
            </h3>
            <p>
              <strong>
                1. Umfang der Verarbeitung personenbezogener Daten
              </strong>
              <br />
              Im Rahmen des Browserspiels &bdquo;Das Kapitalismus Game&ldquo;
              werden personenbezogene Daten nur zur Durchf&uuml;hrung des
              digitalen Spiels verarbeitet und nicht an Dritte weitergegeben.
              Die Erhebung und Verwendung personenbezogener Daten unserer
              Nutzer/-innen erfolgt regelm&auml;&szlig;ig nur nach Einwilligung
              der Nutzer/-innen.
            </p>
            <p>
              <br />
              <strong>
                2. Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener
              </strong>
              Daten
              <br />
              Soweit wir f&uuml;r Verarbeitungsvorg&auml;nge personenbezogener
              Daten eine Einwilligung betroffener Personen einholen, dient Art.
              6 Abs. 1 lit. a) Datenschutzgrundverordnung (DSGVO) als
              Rechtsgrundlage.
            </p>
            <p>
              <br />
              <strong>3. Datenl&ouml;schung und Speicherdauer</strong>
              <br />
              Die personenbezogenen Daten betroffener Personen werden
              gel&ouml;scht, sobald der Zweck der Speicherung entf&auml;llt.
            </p>
            <h3 className="dialog-h3">
              C. Datenverarbeitung durch den Besuch der Website des Kapitalismus
              Games
            </h3>
            <p>
              <strong>
                I. Bereitstellung der Website und Erstellung von Logfiles
              </strong>
              <br />
              <strong>1. Beschreibung und Umfang der Datenverarbeitung</strong>
              <br />
              Bei jedem Aufruf unserer Internetseite erfasst unser System
              automatisiert Daten und Informationen vom Computersystem des
              aufrufenden Rechners.
              <br />
              <br />
              Folgende Daten werden hierbei erhoben:
              <br />
              (1) Informationen &uuml;ber den Browsertyp und die verwendete
              Version
              <br />
              (2) Das Betriebssystem der Nutzenden
              <br />
              (3) Die IP-Adresse der Nutzenden
              <br />
              (4) Datum und Uhrzeit des Zugriffs
              <br />
              (5) Websites, von denen das System der Nutzenden auf unsere
              Internetseite gelangt
              <br />
              (6) Die besuchte URL
              <br />
              <br />
              Die Daten werden nicht als Logfiles in unserem System gespeichert.
              Auch findet keine Speicherung dieser Daten zusammen mit anderen
              personenbezogenen Daten der/s Nutzer/-in statt. Die IP-Adresse der
              Nutzer/-in wird &uuml;ber die Dauer der Spielanwendung hinaus
              nicht gespeichert.
            </p>
            <p>
              <br />
              <strong>2. Rechtsgrundlage f&uuml;r die Datenverarbeitung</strong>
              <br />
              Rechtsgrundlage f&uuml;r die Verarbeitung der Daten und ist Art. 6
              Abs. 1 lit. a) DSGVO
              <br />
              <br />
              <strong>3. Zweck der Datenverarbeitung</strong>
              <br />
              Browser, Betriebssystem und Bildschirmgr&ouml;&szlig;e werden
              genutzt, um eine angepasste Darstellung sicherstellen zu
              k&ouml;nnen. Die IP und URL werden zur Aufrechterhaltung der
              Session genutzt und nicht gespeichert. Es erfolgt &uuml;ber die
              Spielsession hinaus keine Speicherung oder anderweitige Auswertung
              dieser Informationen.
            </p>
            <p>
              <br />
              <strong>4. Dauer der Speicherung</strong>
              <br />
              Die Daten werden gel&ouml;scht, sobald sie f&uuml;r die Erreichung
              des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Im Falle
              der Erfassung der Daten zur Bereitstellung der Website ist dies
              der Fall, wenn die jeweilige Sitzung beendet ist.
              <br />
              <br />
              <strong>5. Widerspruchs- und Beseitigungsm&ouml;glichkeit</strong>
              <br />
              Die Erfassung der Daten zur Bereitstellung der Website ist
              f&uuml;r den Betrieb der Internetseite zwingend erforderlich. Es
              besteht folglich seitens der Nutzer/-in keine
              Widerspruchsm&ouml;glichkeit.
              <br />
              <br />
              <strong>II. Verwendung von Cookies und Kameradaten</strong>
              <br />
              <strong>1. Beschreibung und Umfang der Datenverarbeitung</strong>
              <br />
              <strong>A.) Verwendung von Cookies</strong>
            </p>
            <p>
              Unsere Webseite verwendet Cookies. Bei Cookies handelt es sich um
              Textdateien, die im Internetbrowser bzw. vom Internetbrowser auf
              dem Computersystem des/r Nutzer/-in gespeichert werden. Ruft ein/e
              Nutzer/-in eine Website auf, so kann ein Cookie auf dem
              Betriebssystem des/r Nutzer/-in gespeichert werden. Dieser Cookie
              enth&auml;lt eine charakteristische Zeichenfolge, die eine
              eindeutige Identifizierung des Browsers beim erneuten Aufrufen der
              Website erm&ouml;glicht.
            </p>
            <p>
              Zur Speicherung des Spielstandes setzt das Spiel (mit
              entsprechender Zustimmung) einen Cookie auf Ihrem Rechner. Dieser
              Cookie enth&auml;lt nur den anonymen Spielstand, sodass das Spiel
              auch &uuml;ber mehrere Browser-Sessions hinweg fortgesetzt werden
              kann.
              <br />
              <br />
              Wir setzen Cookies ein, um unsere Website nutzendenfreundlicher zu
              gestalten. Einige Elemente unserer Internetseite erfordern es,
              dass der aufrufende Browser auch nach einem Seitenwechsel
              identifiziert werden kann. In den Cookies werden dabei folgende
              Daten gespeichert und &uuml;bermittelt:
              <br />
              <br />
              (1) Ausf&uuml;hrung von Javascript
              <br />
              (2) Nutzung eines mobilen Endger&auml;tes
              <br />
              (3) Kenntnisnahme des Cookie-Hinweises
              <br />
              (4) Erlaubnis zur Ausspielung eines externen Inhaltes
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong>B.) Nutzung von Kameradaten</strong>
            </p>
            <p>
              Die durch die Kamera erfassten Bilder werden ausschlie&szlig;lich
              lokal im Browser des/r Nutzer/-in analysiert und weder gespeichert
              noch &uuml;bertragen. Weiterverarbeitet wird lediglich ein
              Ergebniswert, der keinerlei R&uuml;ckschl&uuml;sse auf die Person
              zul&auml;sst. Die Kameradaten werden mithilfe eines Machine
              Learning Algorithmus auf die dargestellten Emotionen hin
              analysiert, die dann mit einem Wert zwischen 1 und 10 bewertet
              werden. Diese Information ist f&uuml;r das Spiel erforderlich und
              Teil des Spielprinzips.
              <span>&nbsp;</span>
            </p>
            <p>
              <br />
              <strong>2. Rechtsgrundlage f&uuml;r die Datenverarbeitung</strong>
              <br />
              Die Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener
              Daten unter Verwendung von Cookies und Kameradaten ist Art. 6 Abs.
              1 lit. a) DSGVO.
              <br />
              <br />
              <strong>3. Zweck der Datenverarbeitung</strong>
              <br />
              Der Zweck der Verwendung technisch notwendiger Cookies ist, die
              Nutzung von Websites f&uuml;r die Nutzer/-innen zu vereinfachen.
              Die Verwendung der anonymisierten Kameradaten dienen dazu, den
              Spieleverlauf zu verbessern. Einige Funktionen des Spiels
              k&ouml;nnen ohne den Einsatz von Cookies oder der gesondert
              eingeholten Einwilligung zur Nutzung der Kameradaten nicht
              angeboten werden. F&uuml;r diese ist es erforderlich, dass der
              Browser auch nach einem Seitenwechsel wiedererkannt wird.
            </p>
            <p>
              <br />
              <strong>
                4. Dauer der Speicherung, Widerspruchs- und
                Beseitigungsm&ouml;glichkeit
              </strong>
              <br />
              Cookies werden auf dem Rechner der Nutzer/-in gespeichert und von
              diesem an unserer Seite &uuml;bermittelt. Die technisch
              notwendigen Cookies werden nur bis zum Ende des Nutzungsvorgangs
              gespeichert (Session-Cookies), ebenso wie die Kameradaten. Das
              Cookie zum &quot;Ausblenden des Cookie-Hinweises&quot; wird bis zu
              sieben Tage gespeichert. Die Cookies f&uuml;r das Spiel werden bis
              zu 10 Monate gespeichert. F&uuml;r beide Anwendungen bieten wir
              &quot;Opt-Out-Verfahren&quot; an, mit dem die Speicherung der
              jeweiligen Cookies unterbunden werden kann.
              <br />
              <br />
              Nutzer/innen haben die volle Kontrolle &uuml;ber die Verwendung
              von Cookies. Durch eine &Auml;nderung der Einstellungen in Ihrem
              Internetbrowser k&ouml;nnen sie die &Uuml;bertragung von Cookies
              deaktivieren oder einschr&auml;nken. Bereits gespeicherte Cookies
              k&ouml;nnen jederzeit gel&ouml;scht werden. Dies kann auch
              automatisiert erfolgen. Werden Cookies f&uuml;r unsere Website
              deaktiviert, k&ouml;nnen m&ouml;glicherweise nicht mehr alle
              Funktionen der Website oder des Spiels vollumf&auml;nglich genutzt
              werden.
            </p>
            <h3 className="dialog-h3">
              D. Einbindung externer Inhalte bzw. Dienste
              <span>&nbsp;</span>
            </h3>
            <p>
              <strong>1. Beschreibung und Umfang der Datenverarbeitung</strong>
              <br />
              Im letzten Teil des Spiels wird der Videoplayer des externen
              Google-Dienstes YouTube auf der Seite des &bdquo;Kapitalismus
              Games&ldquo; eingebunden. Das Spiel nutzt diesen Dienst, um den
              Nutzer/-innen die Nutzung eines 360 Grad-Video-Players im
              Spielverlauf zu erm&ouml;glichen. Dabei kann eine
              Daten&uuml;bermittlung von der Seite des &bdquo;Kapitalismus
              Games&ldquo; zu dem Google-Dienst YouTube stattfinden und es
              k&ouml;nnen Cookies durch diesen gesetzt werden. Die Kontrolle
              &uuml;ber die Daten&uuml;bermittlung ist nur m&ouml;glich, wenn
              die Nutzenden der Einbindung des YouTube-Players &uuml;ber den
              Aktivierungskasten aktiv zugestimmt haben. Solange die
              Nutzer/-innen nicht aktiv einwilligen, sich den externen Inhalt
              anzeigen zu lassen, findet keine Daten&uuml;bermittlung statt und
              der Inhalt wird nicht ausgespielt. Im Rahmen der Einbindung des
              Players gelten die dort aufgef&uuml;hrten Datenschutzhinweise
              unter{" "}
              <a
                href="https://policies.google.com/privacy?hl=de"
                target="_blank"
              >
                https://policies.google.com/privacy?hl=de
              </a>
              .<span>&nbsp;</span>
            </p>
            <p>
              Erlauben Nutzer/-innen eine Einbindung von Inhalten des jeweiligen
              externen Dienstes, wird ein Cookie gesetzt, der sich diese
              Erlaubnis merkt (siehe &quot;Verwendung von Cookies&quot;). Der
              Inhalt wird dann ausgespielt und es k&ouml;nnen Daten
              &uuml;bermittelt werden. Der Cookie wird nach der Session
              automatisch gel&ouml;scht.
              <br />
              <br />
              Die &uuml;bermittelten Daten enthalten die Information, dass auf
              den auf der Seite des Kapitalismus Games eingebundenen Inhalt
              zugegriffen wird. Das kann auch personenbezogene Daten (z.B. die
              IP-Adresse) umfassen. Welche Daten jeweils &uuml;bertragen werden,
              ist nicht immer nachvollziehbar. Sie sollten sich daher &uuml;ber
              Datenerhebung, -speicherung und Nutzung bei den jeweiligen
              Anbietern informieren.
              <br />
              <br />
              Die Dienste des folgenden Anbieters werden auf der Seite des
              Kapitalismus Games eingebunden:
            </p>
            <ul className="dialog-ul">
              <li>
                <strong>
                  A) Google (z.B. YouTube-Player, Google Maps, Google
                  Spreadsheets):&nbsp;
                </strong>
                Google-Dienste werden betrieben von Google Inc., 1600
                Amphitheatre Parkway, Mountain View, CA 94043, United States
                (&ldquo;Google&quot;). &Uuml;ber Zweck und Umfang der
                Datenerhebung und die weitere Verarbeitung und Nutzung der Daten
                durch Google sowie Ihre diesbez&uuml;glichen Rechte und
                Einstellungsm&ouml;glichkeiten in den Datenschutzhinweisen von
                Google informieren:&nbsp;{" "}
                <a
                  href="https://policies.google.com/privacy?hl=de"
                  target="_blank"
                >
                  https://policies.google.com/privacy?hl=de
                </a>
              </li>
            </ul>
            <p>
              <br />
            </p>
            <p>
              <strong>2. Rechtsgrundlage f&uuml;r die Datenverarbeitung</strong>
              <br />
              Die Rechtsgrundlage f&uuml;r die &Uuml;bermittlung der Daten ist
              Art. 6 Abs. 1 lit. a) DSGVO.
              <br />
              <br />
              <strong>3. Zweck der Verarbeitung</strong>
              <br />
              Die Einbindung des externen Dienstes auf der Seite des
              Kapitalismus Games dient dem Zweck der Bereitstellung von
              Inhalten, Funktionen und Informationen, die Nutzer/-innen ohne
              diesen Dienst nicht zur Verf&uuml;gung gestellt werden
              k&ouml;nnten.
              <br />
              <br />
              <strong>
                4. Dauer der Speicherung, Widerspruchs- und
                Beseitigungsm&ouml;glichkeit
              </strong>
              <br />
              Welche Daten &uuml;bermittelt und wie lange gespeichert werden,
              h&auml;ngt von den Richtlinien des Google-Dienstes ab. &Uuml;ber
              den Zweck und Umfang der Datenerhebung, die weitere Verarbeitung
              und Nutzung und Speicherung der Daten sowie Ihre Widerspruchs- und
              Beseitigungsrechte m&uuml;ssen Sie sich daher in der
              Datenschutzrichtline von Google informieren:{" "}
              <a
                href="https://policies.google.com/privacy?hl=de"
                target="_blank"
              >
                https://policies.google.com/privacy?hl=de
              </a>
            </p>
            <p>
              <br />
            </p>
            <h3 className="dialog-h3">
              E. Rechte der betroffenen Person
            </h3>
            <p>
              Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie
              Betroffener i.S.d. DSGVO und es stehen Ihnen folgende Rechte
              gegen&uuml;ber dem Verantwortlichen zu:
              <br />
              <br />
              <strong>1. Auskunftsrecht (Art. 15 DSGVO)</strong>
              <br />
              Sie k&ouml;nnen von dem Verantwortlichen eine Best&auml;tigung
              dar&uuml;ber verlangen, ob personenbezogene Daten, die Sie
              betreffen, von uns verarbeitet werden. Liegt eine solche
              Verarbeitung vor, k&ouml;nnen Sie von dem Verantwortlichen
              &uuml;ber folgende Informationen Auskunft verlangen:
              <br />
              <br />
              (1) die Zwecke, zu denen die personenbezogenen Daten verarbeitet
              werden;
              <br />
              (2) die Kategorien von personenbezogenen Daten, welche verarbeitet
              werden;
              <br />
              (3) die Empf&auml;nger bzw. die Kategorien von Empf&auml;ngern,
              gegen&uuml;ber denen die Sie betreffenden personenbezogenen Daten
              offengelegt wurden oder noch offengelegt werden;
              <br />
              (4) die geplante Dauer der Speicherung der Sie betreffenden
              personenbezogenen Daten oder, falls konkrete Angaben hierzu nicht
              m&ouml;glich sind, Kriterien f&uuml;r die Festlegung der
              Speicherdauer;
              <br />
              (5) das Bestehen eines Rechts auf Berichtigung oder L&ouml;schung
              der Sie betreffenden personenbezogenen Daten, eines Rechts auf
              Einschr&auml;nkung der Verarbeitung durch den Verantwortlichen
              oder eines Widerspruchsrechts gegen diese Verarbeitung;
              <br />
              (6) das Bestehen eines Beschwerderechts bei einer
              Aufsichtsbeh&ouml;rde;
              <br />
              (7) alle verf&uuml;gbaren Informationen &uuml;ber die Herkunft der
              Daten, wenn die personenbezogenen Daten nicht bei der betroffenen
              Person erhoben werden.
              <br />
              <br />
              Ihnen steht das Recht zu, Auskunft dar&uuml;ber zu verlangen, ob
              die Sie betreffenden personenbezogenen Daten in ein Drittland oder
              an eine internationale Organisation &uuml;bermittelt werden. In
              diesem Zusammenhang k&ouml;nnen Sie verlangen, &uuml;ber die
              geeigneten Garantien gem. Art. 46 DSGVO im Zusammenhang mit der
              &Uuml;bermittlung unterrichtet zu werden. Es gelten die in &sect;
              34 BDSG geregelten Ausnahmen von diesem Recht.
              <br />
              <br />
              <strong>2. Recht auf Berichtigung (Art. 16 DSGVO)</strong>
              <br />
              Sie haben ein Recht auf Berichtigung und/oder
              Vervollst&auml;ndigung gegen&uuml;ber dem Verantwortlichen, sofern
              die verarbeiteten personenbezogenen Daten, die Sie betreffen,
              unrichtig oder unvollst&auml;ndig sind. Der Verantwortliche hat
              die Berichtigung unverz&uuml;glich vorzunehmen.
              <br />
              <br />
              <strong>
                3. Recht auf Einschr&auml;nkung der Verarbeitung (Art. 18 DSGVO)
              </strong>
              <br />
              Unter den folgenden Voraussetzungen k&ouml;nnen Sie die
              Einschr&auml;nkung der Verarbeitung der Sie betreffenden
              personenbezogenen Daten verlangen:
              <br />
              <br />
              (1) wenn Sie die Richtigkeit der Sie betreffenden
              personenbezogenen f&uuml;r eine Dauer bestreiten, die es dem
              Verantwortlichen erm&ouml;glicht, die Richtigkeit der
              personenbezogenen Daten zu &uuml;berpr&uuml;fen;
              <br />
              (2) die Verarbeitung unrechtm&auml;&szlig;ig ist und Sie die
              L&ouml;schung der personenbezogenen Daten ablehnen und stattdessen
              die Einschr&auml;nkung der Nutzung der personenbezogenen Daten
              verlangen;
              <br />
              (3) der Verantwortliche die personenbezogenen Daten f&uuml;r die
              Zwecke der Verarbeitung nicht l&auml;nger ben&ouml;tigt, Sie diese
              jedoch zur Geltendmachung, Aus&uuml;bung oder Verteidigung von
              Rechtsanspr&uuml;chen ben&ouml;tigen, oder
              <br />
              (4) wenn Sie Widerspruch gegen die Verarbeitung gem&auml;&szlig;
              Art. 21 Abs. 1 DSGVO eingelegt haben und noch nicht feststeht, ob
              die berechtigten Gr&uuml;nde des Verantwortlichen gegen&uuml;ber
              Ihren Gr&uuml;nden &uuml;berwiegen.
              <br />
              <br />
              Wurde die Verarbeitung der Sie betreffenden personenbezogenen
              Daten eingeschr&auml;nkt, d&uuml;rfen diese Daten &ndash; von
              ihrer Speicherung abgesehen &ndash; nur mit Ihrer Einwilligung
              oder zur Geltendmachung, Aus&uuml;bung oder Verteidigung von
              Rechtsanspr&uuml;chen oder zum Schutz der Rechte einer anderen
              nat&uuml;rlichen oder juristischen Person oder aus Gr&uuml;nden
              eines wichtigen &ouml;ffentlichen Interesses der Union oder eines
              Mitgliedstaats verarbeitet werden.
              <br />
              <br />
              Wurde die Einschr&auml;nkung der Verarbeitung nach den o.g.
              Voraussetzungen eingeschr&auml;nkt, werden Sie von dem
              Verantwortlichen unterrichtet bevor die Einschr&auml;nkung
              aufgehoben wird.
              <br />
              <br />
              <strong>4. Recht auf L&ouml;schung (Art. 17 DSGVO)</strong>
              <br />
              <strong>a) L&ouml;schungspflicht</strong>
              <br />
              Sie k&ouml;nnen von dem Verantwortlichen verlangen, dass die Sie
              betreffenden personenbezogenen Daten unverz&uuml;glich
              gel&ouml;scht werden, und der Verantwortliche ist verpflichtet,
              diese Daten unverz&uuml;glich zu l&ouml;schen, sofern einer der
              folgenden Gr&uuml;nde zutrifft:
              <br />
              <br />
              (1) Die Sie betreffenden personenbezogenen Daten sind f&uuml;r die
              Zwecke, f&uuml;r die sie erhoben oder auf sonstige Weise
              verarbeitet wurden, nicht mehr notwendig.
              <br />
              (2) Sie widerrufen Ihre Einwilligung, auf die sich die
              Verarbeitung gem. Art. 6 Abs. 1 lit. a oder Art. 9 Abs. 2 lit. a
              DSGVO st&uuml;tzte, und es fehlt an einer anderweitigen
              Rechtsgrundlage f&uuml;r die Verarbeitung.
              <br />
              (3) Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die
              Verarbeitung ein und es liegen keine vorrangigen berechtigten
              Gr&uuml;nde f&uuml;r die Verarbeitung vor, oder Sie legen gem.
              Art. 21 Abs. 2 DSGVO Widerspruch gegen die Verarbeitung ein.
              <br />
              (4) Die Sie betreffenden personenbezogenen Daten wurden
              unrechtm&auml;&szlig;ig verarbeitet.
              <br />
              (5) Die L&ouml;schung der Sie betreffenden personenbezogenen Daten
              ist zur Erf&uuml;llung einer rechtlichen Verpflichtung nach dem
              Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem
              der Verantwortliche unterliegt.
              <br />
              (6) Die Sie betreffenden personenbezogenen Daten wurden in Bezug
              auf angebotene Dienste der Informationsgesellschaft
              gem&auml;&szlig; Art. 8 Abs. 1 DSGVO erhoben. Es gelten die in
              &sect; 35 BDSG geregelten Ausnahmen von diesem Recht.
              <br />
              <br />
              <strong>b) Information an Dritte</strong>
              <br />
              Hat der Verantwortliche die Sie betreffenden personenbezogenen
              Daten &ouml;ffentlich gemacht und ist er gem. Art. 17 Abs. 1 DSGVO
              zu deren L&ouml;schung verpflichtet, so trifft er unter
              Ber&uuml;cksichtigung der verf&uuml;gbaren Technologie und der
              Implementierungskosten angemessene Ma&szlig;nahmen, auch
              technischer Art, um f&uuml;r die Datenverarbeitung
              Verantwortliche, die die personenbezogenen Daten verarbeiten,
              dar&uuml;ber zu informieren, dass Sie als betroffene Person von
              ihnen die L&ouml;schung aller Links zu diesen personenbezogenen
              Daten oder von Kopien oder Replikationen dieser personenbezogenen
              Daten verlangt haben.
              <br />
              <br />
              <strong>c) Ausnahmen</strong>
              <br />
              Das Recht auf L&ouml;schung besteht nicht, soweit die Verarbeitung
              erforderlich ist
              <br />
              <br />
              (1) zur Aus&uuml;bung des Rechts auf freie
              Meinungs&auml;u&szlig;erung und Information;
              <br />
              (2) zur Erf&uuml;llung einer rechtlichen Verpflichtung, die die
              Verarbeitung nach dem Recht der Union oder der Mitgliedstaaten,
              dem der Verantwortliche unterliegt, erfordert, oder zur
              Wahrnehmung einer Aufgabe, die im &ouml;ffentlichen Interesse
              liegt oder in Aus&uuml;bung &ouml;ffentlicher Gewalt erfolgt, die
              dem Verantwortlichen &uuml;bertragen wurde;
              <br />
              (3) aus Gr&uuml;nden des &ouml;ffentlichen Interesses im Bereich
              der &ouml;ffentlichen Gesundheit gem&auml;&szlig; Art. 9 Abs. 2
              lit. h und i sowie Art. 9 Abs. 3 DSGVO;
              <br />
              (4) f&uuml;r im &ouml;ffentlichen Interesse liegende Archivzwecke,
              wissenschaftliche oder historische Forschungszwecke oder f&uuml;r
              statistische Zwecke gem. Art. 89 Abs. 1 DSGVO, soweit das unter
              Abschnitt a) genannte Recht voraussichtlich die Verwirklichung der
              Ziele dieser Verarbeitung unm&ouml;glich macht oder ernsthaft
              beeintr&auml;chtigt, oder
              <br />
              (5) zur Geltendmachung, Aus&uuml;bung oder Verteidigung von
              Rechtsanspr&uuml;chen.
              <br />
              <br />
              <strong>5. Recht auf Unterrichtung (Art. 19 DSGVO)</strong>
              <br />
              Haben Sie das Recht auf Berichtigung, L&ouml;schung oder
              Einschr&auml;nkung der Verarbeitung gegen&uuml;ber dem
              Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen
              Empf&auml;ngern, denen die Sie betreffenden personenbezogenen
              Daten offengelegt wurden, diese Berichtigung oder L&ouml;schung
              der Daten oder Einschr&auml;nkung der Verarbeitung mitzuteilen, es
              sei denn, dies erweist sich als unm&ouml;glich oder ist mit einem
              unverh&auml;ltnism&auml;&szlig;igen Aufwand verbunden.
              <br />
              <br />
              Ihnen steht gegen&uuml;ber dem Verantwortlichen das Recht zu,
              &uuml;ber diese Empf&auml;nger unterrichtet zu werden.
              <br />
              <br />
              <strong>
                6. Recht auf Daten&uuml;bertragbarkeit (Art. 20 DSGVO)
              </strong>
              <br />
              Sie haben das Recht, die Sie betreffenden personenbezogenen Daten,
              die Sie dem Verantwortlichen bereitgestellt haben, in einem
              strukturierten, g&auml;ngigen und maschinenlesbaren Format zu
              erhalten. Au&szlig;erdem haben Sie das Recht diese Daten einem
              anderen Verantwortlichen ohne Behinderung durch den
              Verantwortlichen, dem die personenbezogenen Daten bereitgestellt
              wurden, zu &uuml;bermitteln, sofern
              <br />
              <br />
              (1) die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1
              lit. a DSGVO oder Art. 9 Abs. 2 lit. a DSGVO oder auf einem
              Vertrag gem. Art. 6 Abs. 1 lit. b DSGVO beruht und
              <br />
              (2) die Verarbeitung mithilfe automatisierter Verfahren erfolgt.
              <br />
              <br />
              In Aus&uuml;bung dieses Rechts haben Sie ferner das Recht, zu
              erwirken, dass die Sie betreffenden personenbezogenen Daten direkt
              von einem Verantwortlichen einem anderen Verantwortlichen
              &uuml;bermittelt werden, soweit dies technisch machbar ist.
              Freiheiten und Rechte anderer Personen d&uuml;rfen hierdurch nicht
              beeintr&auml;chtigt werden.
              <br />
              <br />
              Das Recht auf Daten&uuml;bertragbarkeit gilt nicht f&uuml;r eine
              Verarbeitung personenbezogener Daten, die f&uuml;r die Wahrnehmung
              einer Aufgabe erforderlich ist, die im &ouml;ffentlichen Interesse
              liegt oder in Aus&uuml;bung &ouml;ffentlicher Gewalt erfolgt, die
              dem Verantwortlichen &uuml;bertragen wurde.
              <br />
              <br />
              <strong>7. Widerspruchsrecht (Art. 21 DSGVO)</strong>
              <br />
              Sie haben das Recht, aus Gr&uuml;nden, die sich aus ihrer
              besonderen Situation ergeben, jederzeit gegen die Verarbeitung der
              Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6
              Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen.
              <br />
              <br />
              Der Verantwortliche verarbeitet die Sie betreffenden
              personenbezogenen Daten nicht mehr, es sei denn, er kann zwingende
              schutzw&uuml;rdige Gr&uuml;nde f&uuml;r die Verarbeitung
              nachweisen, die Ihre Interessen, Rechte und Freiheiten
              &uuml;berwiegen, oder die Verarbeitung dient der Geltendmachung,
              Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen.
              <br />
              <br />
              Werden die Sie betreffenden personenbezogenen Daten verarbeitet,
              um Direktwerbung zu betreiben, haben Sie das Recht, jederzeit
              Widerspruch gegen die Verarbeitung der Sie betreffenden
              personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen.
              <br />
              <br />
              Widersprechen Sie der Verarbeitung f&uuml;r Zwecke der
              Direktwerbung, so werden die Sie betreffenden personenbezogenen
              Daten nicht mehr f&uuml;r diese Zwecke verarbeitet.
              <br />
              <br />
              Sie haben die M&ouml;glichkeit, im Zusammenhang mit der Nutzung
              von Diensten der Informationsgesellschaft &ndash; ungeachtet der
              Richtlinie 2002/58/EG &ndash; Ihr Widerspruchsrecht mittels
              automatisierter Verfahren auszu&uuml;ben, bei denen technische
              Spezifikationen verwendet werden. Es gelten die in &sect; 36 BDSG
              geregelten Ausnahmen von diesem Recht.
              <br />
              <br />
              <strong>
                8. Recht auf Widerruf der datenschutzrechtlichen
                Einwilligungserkl&auml;rung (Art. 7 DSGVO)
              </strong>
              <br />
              Sie haben das Recht, Ihre datenschutzrechtliche
              Einwilligungserkl&auml;rung jederzeit zu widerrufen. Durch den
              Widerruf der Einwilligung wird die Rechtm&auml;&szlig;igkeit der
              aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung
              nicht ber&uuml;hrt.
              <br />
              <br />
              <strong>
                9. Recht auf Beschwerde bei einer Aufsichtsbeh&ouml;rde (Art. 77
                DSGVO)
              </strong>
              <br />
              Unbeschadet eines anderweitigen verwaltungsrechtlichen oder
              gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde
              bei einer Aufsichtsbeh&ouml;rde, insbesondere in dem Mitgliedstaat
              ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des
              mutma&szlig;lichen Versto&szlig;es, zu, wenn Sie der Ansicht sind,
              dass die Verarbeitung der Sie betreffenden personenbezogenen Daten
              gegen die DSGVO verst&ouml;&szlig;t.
              <br />
              <br />
              Die Aufsichtsbeh&ouml;rde, bei der die Beschwerde eingereicht
              wurde, unterrichtet den Beschwerdef&uuml;hrer &uuml;ber den Stand
              und die Ergebnisse der Beschwerde einschlie&szlig;lich der
              M&ouml;glichkeit eines gerichtlichen Rechtsbehelfs nach Art. 78
              DSGVO.
              <br />
              <br />
              Aufsichtsbeh&ouml;rde der Bundeszentrale f&uuml;r politische
              Bildung ist der Bundesbeauftragte f&uuml;r den Datenschutz und die
              Informationsfreiheit, Graurheindorfer Stra&szlig;e 153, 53117
              Bonn.
            </p>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
