import React from "react";

function Icon({ width, height, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 1600 1600"
    >
      <path
        fill="currentColor"
        d="M-5360-550c-442.666,0-800,357.335-800,800s357.334,800,800,800,800-357.335,800-800S-4917.335-550-5360-550Zm0,1493.335c-384,0-693.335-309.335-693.335-693.335S-5744-443.335-5360-443.335-4666.666-134-4666.666,250-4976,943.335-5360,943.335Z"
        transform="translate(6160 550)"
      />
      <path
        fill="currentColor"
        d="M-6065.322-243.955l57.053,75.965c81.315-61.075,173.994-93.36,268.016-93.36s186.7,32.285,268.01,93.36l57.063-75.965c-97.9-73.53-210.307-112.395-325.072-112.395S-5967.426-317.485-6065.322-243.955Z"
        transform="translate(6538.713 1324.6)"
      />
      <circle
        fill="currentColor"
        cx="106.667"
        cy="106.667"
        r="106.667"
        transform="translate(499.185 523.333)"
      />
      <circle
        fill="currentColor"
        cx="106.667"
        cy="106.667"
        r="106.667"
        transform="translate(887.478 523.333)"
      />
      <rect
        fill="currentColor"
        width="214.49"
        height="94.999"
        transform="translate(469.517 420.791) rotate(-40)"
      />
      <rect
        fill="currentColor"
        width="94.999"
        height="214.49"
        transform="matrix(0.643, -0.766, 0.766, 0.643, 905.11, 355.693)"
      />
    </svg>
  );
}

export default Icon;
